import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useEffect } from 'react';
import Home from "./components/Hero";
import Profile from './pages/Profile'
import SignIn from "./pages/SignIn";
import SignUp from "./pages/SignUp";
import PrivateRoute from "./components/PrivateRoute";
import ForgotPassword from "./pages/ForgotPassword";
import Navbar from "./components/Navbar";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CreateListing from "./pages/CreateListing";
import EditListing from "./pages/EditListing";
import EditCommercial from "./pages/EditCommercial";
import EditResidential from "./pages/EditResidential";
import Listing from "./pages/Listing";
import Category from "./pages/Category";
import Buy from "./pages/Buy";
import Sell from "./pages/Sell";
import AboutUs from "./pages/AboutUs";
import ContactUs from "./components/ContactUs";
import Properties from "./pages/Properties";
import Feedback from "./pages/Feedback";
import Privacy from "./pages/Privacy";
import CommercialProjects from "./pages/CommercialProjects";
import ResidentialProjects from "./pages/ResidentialProjects";
import Term from "./pages/Term";
import Rent from './pages/Rent';
import Footer from "./components/Footer/Footer";
import ProjectDescription from "./pages/ProjectDescription";
import ResidentialProjectDescription from './pages/ResidentialProjectDescription'
import Residential from "./pages/Residential";
import CreateProject from './pages/CreateProjects'
import ProjectPrivateRoute from './components/ProjectPrivateRoute.jsx'
import PropertyForm from "./pages/Projects/index.js"; 
import Projects from './pages/AllProjects.jsx'
import Emi from "./pages/Emi";
import ReactGA from "react-ga";
import BuildImg from "./components/BuildImg";
import Interior from "./pages/Interior";
import Move from "./pages/Move";
// import Blog from "./pages/Blog";
import Training from "./components/Training";
const TRACKING_ID = "G-P4PT1KJLDT"
ReactGA.initialize(TRACKING_ID);
function initializeReactGA() {
  ReactGA.initialize('G-P4PT1KJLDT');
  ReactGA.pageview(window.location.pathname + window.location.search);
} 
function App() {
  useEffect(() => {
    initializeReactGA();
  }, []);
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }, []);
  return (
    <div>
      <Router>
        <Navbar />
        <Routes>
          <Route path="/rent" element={<Rent />} />
          <Route path="/" element={<Home/>} />
          <Route path="/buy" element={<Buy />} />
          <Route path="/sell" element={<Sell />} />
          <Route path='/createproject' element={<CreateProject />} />
          <Route path="/aboutus" element ={<AboutUs />} />
          <Route path="/contactus" element={<ContactUs/>} />
          <Route
            path="/project/:projectName/:projectId"
            element={<ProjectDescription />}
          />
          <Route path="/allprojects" element={<Projects />} />
          <Route
            path="/residentialproject/:projectName/:projectId"
            element={<ResidentialProjectDescription />}
          />
            <Route
            path="/project/:projectName/:projectId"
            element={<Residential />}
          />
          <Route path="/privacy" element={<Privacy/>} />
          <Route path="/term" element={<Term/>} /> 
          <Route path="/feedback" element={<Feedback/>} />
          <Route path="/emi" element={<Emi/>} />
          <Route path="/Properties" element={<Properties/>} />
          <Route path="/profile" element={<PrivateRoute />}>
          <Route path="/profile" element={<Profile />} />
          </Route>
          <Route path="/sign-in" element={<SignIn />} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route
            path="/category/:categoryName/:listingId"
            element={<Listing />}
          /> 
          <Route path="/commercialprojects" element={<CommercialProjects />} />
          <Route path="/residentialprojects" element={<ResidentialProjects />} />
          <Route path="/category/:categoryName" element={<Category />} />
          <Route path="create-listing" element={<PrivateRoute />}>
            <Route path="/create-listing" element={<CreateListing />} />
          </Route>
          <Route path="edit-listing" element={<PrivateRoute />}>
          <Route path="/edit-listing/:listingId" element={<EditListing />} />
          </Route>
          <Route path="edit-commercial" element={<PrivateRoute />}>
          <Route path="/edit-commercial/:listingId" element={<EditCommercial />} />
          </Route>
          <Route path="edit-residential" element={<PrivateRoute />}>
          <Route path="/edit-residential/:listingId" element={<EditResidential/>} />
          </Route>
          <Route path="create-project" element={<ProjectPrivateRoute />}>
            <Route path="/create-project" element={<PropertyForm />} />
          </Route>
          <Route path="/interior" element={<Interior />} />
          <Route path="/move-packers" element={<Move />} />
          <Route path="/training" element={<Training/>} />
          {/* <Route path="/blog" element={<Blog />} /> */}
        </Routes>
       </Router> 
 <ToastContainer
        position="bottom-center"
        autoClose={5000}  
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <Footer />
      <BuildImg/>
     </div>
  );
}
export default App;