/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { collection, getDocs, orderBy, query } from 'firebase/firestore';
import { db } from '../firebase';
import CommercialProjects from '../pages/CommercialProjects';
import ResidentialProjects from '../pages/ResidentialProjects';
import "../pages/AllProjects.css";
function Projects({listing, id , isLoggedIn ,onEditd, onDeleted ,onEditdd, onDeletedd}) {
  const [selectedOption, setSelectedOption] = useState('default');
  const [projects, setProjects] = useState(null);

  // Handle the select option change
  function handleOptionChange(event) {
    setSelectedOption(event.target.value);
  }

  // Fetch the listings from the selected collection
  useEffect(() => {
    async function fetchListings() {
      try {
        const listingsRef = collection(db, selectedOption);
        const q = query(listingsRef, orderBy('timestamp', 'desc'));
        const querySnap = await getDocs(q);
        const plistings = [];
        querySnap.forEach((doc) => {
          return plistings.push({
            id: doc.id,
            data: doc.data(),
          });
        });
        setProjects(plistings);
      } catch (error) {
        console.log(error);
      }
    }
    fetchListings();
  }, [selectedOption]);

  return (
    <>
    <div>
    <select class="option-select" value={selectedOption} onChange={handleOptionChange}>
      <option value="default">Select Your Project</option>
      <option value="projects">Commercial Project</option>
      <option value="residential_project">Residential Project</option>
    </select>
    {selectedOption === 'default' && <CommercialProjects />}
    {selectedOption === 'projects' && <CommercialProjects />}
    {selectedOption === 'residential_project' && <ResidentialProjects />}
  </div>
  
  </>
  );
}

export default Projects;
