import React, { useState } from "react";
import "./Feedback.css";
  const FeedbackForm = () => {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [feedback, setFeedback] = useState("");
  const [topic1, setTopic1] = useState("");
  const [topic2, setTopic2] = useState("");
  const [message, setMessage] = useState("");
  const handleSubmit = (event) => {
    event.preventDefault();
    //handle form submission here
  }; 
 return (
    <div className="backi">
    <div className="for">
    <form className="fo" onSubmit={handleSubmit}>
    <div> <div>
              <label>
                <input
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Enter your name*"
                  required
                />
              </label>
            </div>
            <div>
              <label>
                <input
                  type="tel"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  placeholder="Enter your phone number*"
                  required
                />
              </label>
            </div>
        <div>
          <label>
            <select value={topic1} onChange={(e) => setTopic1(e.target.value)} required>
              <option value="">I am a*</option>
              <option value="Home Buyer">Home Buyer</option>
              <option value="Home Seller">Home Seller</option>
              <option value="Home Buyer & Home Seller">Home Buyer & Home Seller</option>
              <option value="Real Estate Agent">Real Estate Agent</option>
              <option value="Real Estate Broker">Real Estate Broker</option>
              <option value="Rentar">Rentar</option>
              <option value="Landlord">Landlord</option>
              <option value="Investor">Investor</option>
              <option value="Lender">Lender</option>
              <option value="Builder">Builder</option>
              <option value="Other">Other</option>
            </select>
          </label>
        </div>
        <div>
          <label>
            <select value={topic2} onChange={(e) => setTopic2(e.target.value)} required>
              <option value="">Select Topic*</option>
              <option value="Property">Property</option>
              <option value="Rental Property">Rental Property</option>
              <option value="Buying">Buying</option>
              <option value="Selling">Selling</option>
              <option value="Experience">Experience</option>
              <option value="Search">Search</option>
              <option value="Find a Realtor">Find a Realtor</option>
              <option value="My Account">My Account</option>
              <option value="My Home">My Home</option>
              <option value="News">News</option>
              <option value="Other">Other</option>
            </select>
          </label>
        </div>
        <div>
          <label>
            <select value={feedback} onChange={(e) => setFeedback(e.target.value)} required>
              <option value="">More Specifically*</option>
              <option value="Suggestion">Suggestion</option>
              <option value="Technical Issue">Technical Issue</option>
              <option value="Performance">Performance</option>
              <option value="Kudos">Kudos</option>
              <option value="other">Other</option>
              </select>
              </label>
    </div>
          <div>
          <label>
          <textarea value={message}   placeholder="Tell us what's on your mind:*" onChange={(e) => setMessage(e.target.value)} />
          </label>
          </div>
          <button className="sharebutton" type="submit">Share</button>
          </div>
          </form>
          </div>
          </div>
          );
          };
          export default FeedbackForm;
