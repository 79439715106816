/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useRef } from "react";
import Cityy from "./City";
import { FaLock } from "react-icons/fa";
import { FaChartLine } from "react-icons/fa";
import { FaShieldAlt } from "react-icons/fa";
import { FaMoneyBillWave } from "react-icons/fa";
import gif from "../assets/svg/imgecp.jpeg";
import giff from "../assets/svg/request.gif";
import video from "../assets/svg/video.mp4";
import Listingproject from "./Listingproject";
import ResidentialListingProjects from "./ResidentialListingProjects";
import "./Hero.css";
import Why from "./Why";
import { FaAngleRight } from "react-icons/fa";
import Prop from "../assets/svg/Prop.jpeg";
import Prorent from "../assets/svg/rent-wooden-blocks-with-magnifying-glass.jpeg";
import Probuy from "../assets/svg/Buying-Distressed-Properties.png";
import Propro from "../assets/svg/Pro.jpeg";
import exp from "../assets/svg/lovepik-astronaut-exploring-the-moon-png-image_401578385_wh1200.png";
import electricdesign from "../assets/svg/electric.jpeg";
import gaurs from "../assets/svg/gaurs.png";
import bhutani from "../assets/svg/bhutani.png";
import godrej from "../assets/svg/godrej.png";
import prosper from "../assets/svg/prosper.png";
import raheja from "../assets/svg/raheja.png";
import ace from "../assets/svg/acegroup.png";
import gulshan from "../assets/svg/gulshan.jpeg";
import non from "../assets/svg/non.png";
import ats from "../assets/svg/ats.png";
import dlf from "../assets/svg/dlf.png";
import landscience from "../assets/svg/landscience.png";
import fairfox from "../assets/svg/fairfox.png";
import orion from "../assets/svg/orion.png";
import movers from "../assets/svg/movers.jpg";
import Modal from "../components/Modal";
import ShowModal from "../components/ShowModal";
import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import ListingItem from "../components/ListingItem";
import { db } from "../firebase";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import ReactGA from "react-ga";
import SearchBar from "./Search";

const TRACKING_ID = "G-P4PT1KJLDT";
ReactGA.initialize(TRACKING_ID);
function initializeReactGA() {
  ReactGA.initialize("G-P4PT1KJLDT");
  ReactGA.pageview(window.location.pathname + window.location.search);
}
const settings = {
  slidesToShow: 4,
  slidesToScroll: 4,
  responsive: [
    {
      breakpoint: 1040,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
  prevArrow: <FaChevronLeft className="slick-arrow slick-prev" />,
  nextArrow: <FaChevronRight className="slick-arrow slick-next" />,
};

const Hero = () => {
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }, []);

  //The featured Commercial  projects
  const [projects, setProjects] = useState(null);
  useEffect(() => {
    async function fetchListings() {
      try {
        // get reference
        const listingsRef = collection(db, "projects");
        // create the query
        const q = query(listingsRef, orderBy("timestamp", "desc"));
        // execute the query
        const querySnap = await getDocs(q);
        const plistings = [];
        querySnap.forEach((doc) => {
          return plistings.push({
            id: doc.id,
            data: doc.data(),
          });
        });
        setProjects(plistings);
      } catch (error) {
        console.log(error);
      }
    }
    fetchListings();
  }, []);

  //The features Residential Projects
  const [residentialProjects, setResidentialProjects] = useState([]);

  useEffect(() => {
    async function fetchResidentialProjects() {
      try {
        const residentialProjectsRef = collection(db, "ResidentialProjects");
        const q = query(residentialProjectsRef, orderBy("timestamp", "desc"));
        const querySnap = await getDocs(q);
        const projectsList = [];
        querySnap.forEach((doc) => {
          projectsList.push({
            id: doc.id,
            data: doc.data(),
          });
        });
        setResidentialProjects(projectsList);
      } catch (error) {
        console.log(error);
      }
    }
    fetchResidentialProjects();
  }, []);

  // Offers
  const [offerListings, setOfferListings] = useState(null);
  useEffect(() => {
    async function fetchListings() {
      try {
        // get reference
        const listingsRef = collection(db, "listings");
        // create the query
        const q = query(
          listingsRef,
          where("offer", "==", true),
          orderBy("timestamp", "desc"),
          limit(4)
        );
        // execute the query
        const querySnap = await getDocs(q);
        const listings = [];
        querySnap.forEach((doc) => {
          return listings.push({
            id: doc.id,
            data: doc.data(),
          });
        });
        setOfferListings(listings);
      } catch (error) {
        console.log(error);
      }
    }
    fetchListings();
  }, []);
  // Places for rent
  const [rentListings, setRentListings] = useState(null);
  useEffect(() => {
    async function fetchListings() {
      try {
        // get reference
        const listingsRef = collection(db, "listings");
        // create the query
        const q = query(
          listingsRef,
          where("type", "==", "rent"),
          orderBy("timestamp", "desc"),
          limit(4)
        );
        const querySnap = await getDocs(q);
        const listings = [];
        querySnap.forEach((doc) => {
          return listings.push({
            id: doc.id,
            data: doc.data(),
          });
        });
        setRentListings(listings);
      } catch (error) {
        console.log(error);
      }
    }
    fetchListings();
  }, []);
  //Places to Buy
  const [buyListings, setbuyListings] = useState(null);
  useEffect(() => {
    async function fetchListings() {
      try {
        // get reference
        const listingsRef = collection(db, "listings");
        // create the query
        const q = query(
          listingsRef,
          where("type", "==", "buy"),
          orderBy("timestamp", "desc"),
          limit(4)
        );
        // execute the query
        const querySnap = await getDocs(q);
        const listings = [];
        querySnap.forEach((doc) => {
          return listings.push({
            id: doc.id,
            data: doc.data(),
          });
        });
        setbuyListings(listings);
      } catch (error) {
        console.log(error);
      }
    }
    fetchListings();
  }, []);
  // Places for sale
  const [saleListings, setSaleListings] = useState(null);
  useEffect(() => {
    async function fetchListings() {
      try {
        // get reference
        const listingsRef = collection(db, "listings");
        // create the query
        const q = query(
          listingsRef,
          where("type", "==", "sale"),
          orderBy("timestamp", "desc"),
          limit(4)
        );
        // execute the query
        const querySnap = await getDocs(q);
        const listings = [];
        querySnap.forEach((doc) => {
          return listings.push({
            id: doc.id,
            data: doc.data(),
          });
        });
        setSaleListings(listings);
      } catch (error) {
        console.log(error);
      }
    }
    fetchListings();
  }, []);

  //ModalConcept
  const [openModal, setOpenModal] = useState(false);
  const onHandleClick = () => {
    setOpenModal(true);
  };
  const onHandleClose = () => {
    setOpenModal(false);
  };

  const imageContainerRef = useRef(null);
  const scrollLeft = () => {
    imageContainerRef.current.scrollBy({
      left: -imageContainerRef.current.offsetWidth, // Scroll by the width of the container
      behavior: "smooth",
    });
  };
  const scrollRight = () => {
    const container = imageContainerRef.current;
    const { scrollWidth, clientWidth, scrollLeft } = container;

    if (scrollLeft + clientWidth >= scrollWidth) {
      container.scrollTo({ left: 0, behavior: "smooth" });
    } else {
      container.scrollBy({
        left: container.offsetWidth,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    const interval = setInterval(scrollRight, 4000); // Adjust the interval duration as desired (2000ms = 2 seconds)

    return () => {
      clearInterval(interval); // Clean up the interval when the component unmounts
    };
  }, []);
  return (
    <>
      <div className="lg:hidden text-center text-sm disable-copy">
        Powered by-Prop10X Private Limited
      </div>
      <div className="disable-copy">
        <SearchBar />
      </div>
      <div className="h-full max-h-full mb-8 xl:mb-20 disable-copy">
        <FloatingWhatsApp
          accountName="Property10X"
          phoneNumber="9871424020"
          avatar={Prop}
          notification
          notificationSound
          statusMessage="Replies within a minute"
          allowEsc
          chatMessage="Welcome to Property10X"
          allowClickAway={true}
        />
        <Link to="/contactus">
          <div className="container contoffer">
            <div className="offer">
              <span>
                🏠Rose Avenue Available-The Dream Residential Project🏠
              </span>
            </div>
          </div>
        </Link>
        <div>
          <h2 className="newss">
            <span className="text-black">What's happening in</span>
            <span className="text-[#005978]"> Property10X! </span>
          </h2>
        </div>
        <div className="flex items-center lg:justify-between flex-col lg:flex-row mt-8 lg:mt-4 m-20">
          <div className="flex flex-col mt-4 lg:mt-0">
            <span className="text-3xl text-[#005978] font-bold text-center lg:text-left">
              6000+
            </span>
            <span className="text-s font-semibold">Strong Investor Base</span>
          </div>
          <div className="hidden lg:block border-l-2 border-[#005978] h-12 my-auto mx-8"></div>
          <div className="flex flex-col mt-8 lg:mt-0">
            <span className="text-3xl text-[#005978] font-bold text-center lg:text-left">
              10 Years+
            </span>
            <span className="text-s font-semibold text-center">
              of Valuable industry experience
            </span>
          </div>
          <div className="hidden lg:block border-l-2 border-[#005978] h-12 my-auto mx-8"></div>
          <div className="flex flex-col mt-8 lg:mt-0">
            <span className="text-3xl text-[#005978] font-bold text-center lg:text-left">
              150 Cr+
            </span>
            <span className="text-s font-semibold text-center">
              Worth high-value transaction
            </span>
          </div>
        </div>
        {/* project section */}
        {projects && projects.length > 0 && (
          <div className=" text-center  mt-20 m-6 mb-6 bg-[#F5F5F5]">
            <h2 className=" px-3  text-3xl mt-6 font-semibold text-black">
              <span className="text-[#005978] e">Commercial Projects</span>
            </h2>
            <Link to="/commercialprojects">
              <p className="font-bold px-3 py-2 text-l text-[#005978] hover:text-blue-800 transition duration-150 ease-in-out ">
                Show more places for Projects{" "}
                <FaAngleRight className="inline-block ml-1" />{" "}
              </p>
            </Link>
            <Slider {...settings}>
              {projects.map((listing) => (
                <Listingproject
                  key={listing.id}
                  listing={listing.data}
                  id={listing.id}
                />
              ))}
            </Slider>
          </div>
        )}
        {residentialProjects && residentialProjects.length > 0 && (
          <div className=" text-center  mt-20 m-6 mb-6 bg-[#F5F5F5]">
            <h2 className=" px-3  text-3xl mt-6 font-semibold text-black">
              <span className="text-[#005978] e">Residential Projects</span>
            </h2>
            <Link to="/residentialprojects">
              <p className="font-bold px-3 py-2 text-l text-[#005978] hover:text-blue-800 transition duration-150 ease-in-out ">
                Show more places for Projects{" "}
                <FaAngleRight className="inline-block ml-1" />{" "}
              </p>
            </Link>
            <Slider {...settings}>
              {residentialProjects.map((listing) => (
                <ResidentialListingProjects
                  key={listing.id}
                  listing={listing.data}
                  id={listing.id}
                />
              ))}
            </Slider>
          </div>
        )}
        {/* ------------------ */}
        {buyListings && buyListings.length > 0 && (
          <div className=" mt-20 m-2 mb-6 bg-[#F5F5F5]">
            <h2 className=" px-3 text-4xl mt-6 font-semibold">
              Start{" "}
              <span className="text-[#005978]  underline"> Investing</span>
            </h2>
            <Link to="/Buy">
              <p className="font-bold px-3 text-sm text-[#005978] hover:text-blue-800 transition duration-150 ease-in-out ">
                Show more places for buy
              </p>
            </Link>
            <ul className="sm:grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 ">
              {buyListings.map((listing) => (
                <ListingItem
                  key={listing.id}
                  listing={listing.data}
                  id={listing.id}
                />
              ))}
            </ul>
          </div>
        )}
        {rentListings && rentListings.length > 0 && (
          <div className="mt-20 m-2 mb-6 bg-[#F5F5F5]">
            <h2 className="px-3 text-4xl mt-6 font-semibold">
              Places for <span className="text-[#005978] underline">Rent</span>
            </h2>
            <Link to="/rent">
              <p className="font-bold px-3 text-sm text-[#005978] hover:text-blue-800 transition duration-150 ease-in-out">
                Show more places for rent
              </p>
            </Link>
            <ul className="sm:grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 ">
              {rentListings.map((listing) => (
                <ListingItem
                  key={listing.id}
                  listing={listing.data}
                  id={listing.id}
                />
              ))}
            </ul>
          </div>
        )}
        {saleListings && saleListings.length > 0 && (
          <div className="mt-20 m-2 mb-6 bg-[#F5F5F5]">
            <h2 className="px-3 text-4xl mt-6 font-semibold">
              Places for <span className="text-[#005978] underline">Sale</span>
            </h2>
            <Link to="/Sell">
              <p className=" font-bold px-3 text-sm text-[#005978] hover:text-blue-800 transition duration-150 ease-in-out">
                Show more places for sale
              </p>
            </Link>
            <ul className="sm:grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
              {saleListings.map((listing) => (
                <ListingItem
                  key={listing.id}
                  listing={listing.data}
                  id={listing.id}
                />
              ))}
            </ul>
          </div>
        )}
        {/* <div><h2 className='sp mb-2'> Our Clients Thought</h2></div>
        <div class="card-container">
          <div className="card">
              <img src="" alt=" " />
              <div className="card-content">
                <h3 className='text-[#005978] font-semibold'>Client 1</h3>
                <p>Looking for a new place to call home? Browse our selection of rental properties and find the perfect fit for you with ease. Rent a home today!</p>
              </div>
          </div>
          <div className="card">
              <img src="" alt="" />
              <div className="card-content">
                <h3 className='text-[#005978] font-semibold'>Client 2</h3>
                <p>Looking to become a homeowner? Discover your dream home with our wide range of properties for sale. Browse our selection and find the perfect fit for you. Buy your home today!</p>
              </div>
          </div>

          <div className="card">
              <img src="" alt="Card Imagee" />
              <div className="card-content">
                <h3 className='text-[#005978] font-semibold'>Client 3</h3>
                <p>Looking for your next big project? Check out our portfolio of exciting projects in various fields, Explore our projects today!</p>
              </div>
          </div>
          <div className="card">
              <img src="" alt="Card Imagee" />
              <div className="card-content">
                <h3 className='text-[#005978] font-semibold'>Client 4</h3>
                <p>Ready to discover more? Explore now and find out what we have in store for you. Don't wait, start exploring today!</p>
              </div>
          </div>
        </div> */}
        <div>
          <h2 className="sp mb-2">
            <span className="text-black">Our </span> Buisness Partners
          </h2>
        </div>
        <div className="running-images">
          <div className="arrow arrow-left" onClick={scrollLeft}>
            <span>&lt;</span>
          </div>
          <div
            className="image-container"
            ref={imageContainerRef}
            onClick={onHandleClick}
          >
            <div className="image-box">
              <img src={gaurs} alt="Image1" />
            </div>
            <div className="image-box">
              <img src={bhutani} alt="Image2" />
            </div>
            <div className="image-box">
              <img src={godrej} alt="Image3" />
            </div>
            <div className="image-box">
              <img src={prosper} alt="Image4" />
            </div>
            <div className="image-box">
              <img src={orion} alt="Image5" />
            </div>
            <div className="image-box">
              <img src={raheja} alt="Image6" />
            </div>
            <div className="image-box">
              <img src={ace} alt="Image7" />
            </div>
            <div className="image-box">
              <img src={gulshan} alt="Image8" />
            </div>
            <div className="image-box">
              <img src={non} alt="Image9" />
            </div>
            <div className="image-box">
              <img src={ats} alt="Image10" />
            </div>
            <div className="image-box">
              <img src={dlf} alt="Image11" />
            </div>
            <div className="image-box">
              <img src={landscience} alt="Image12" />
            </div>
            <div className="image-box">
              <img src={fairfox} alt="Image13" />
            </div>
          </div>
          <div className="arrow arrow-right" onClick={scrollRight}>
            <span>&gt;</span>
          </div>
        </div>
        <div>
          <h2 className="sp mb-2">
            <span className="text-black">To make your </span> Home beautiful ⏰
          </h2>
        </div>
        <div className="electriccontainer">
          <div className="electriccontent">
            <h2 className="text-4xl font-bold text-start text-[#005978] mt-2  ">
              Interior Design!
            </h2>
            <p className=" text-lg font-semibold">
              Design is not just what it looks like and feels like. Design is
              how it works.
            </p>
            <Link to="/interior">
              <button className="homebutton mt-8">Book your designer</button>
            </Link>
          </div>
          <div className="electricimage">
            <img className="imm" src={electricdesign} alt="Imagee"></img>
          </div>
        </div>
        <div>
          <h2 className="newss">
            <span className="text-black">News & </span>Insights
          </h2>{" "}
        </div>
        <div className="container vid">
          <h2 className="text-[#005978] text-3xl">Property10X</h2>
          <video className="background-video" autoPlay muted loop>
            <source src={video} type="video/mp4" />
          </video>
          <div className="content ">
            <button
              className="buut"
              onClick={() =>
                (window.location.href =
                  "https://realty.economictimes.indiatimes.com/")
              }
            >
              Check Latest Updates
            </button>
          </div>
        </div>
        <div className="container explore mt-[40px]">
          <div className="text-container start">
            <h2 className="ss">
              <Link to="/allprojects" className="link add">
                <span className="text-black">Start</span> Exploring
              </Link>
            </h2>
            <p className="ex">
              "Home is where love resides, memories are created, friends always
              belong, and laughter never ends" -
              <span className="text-[#005978] text-xl underline">
                {" "}
                Property10X
              </span>
            </p>
            <button className="button but animate-bounce">
              <Link to="/allprojects" className="button-link">
                Explore Now
              </Link>
            </button>
          </div>
          <div className="image-container ">
            <img className="simh" src={gif} alt="Exploring" />
          </div>
        </div>
        <div className="beni">
          <h2 className="newss">
            <span className="text-black">Benefits &</span> Insights
          </h2>
          <p className="choose">
            Why choose <span className="ch">Property10X!</span>
          </p>
          <div className="container saf ">
            <div className="row">
              <div className="col-md-6">
                <div className="section sec">
                  <FaLock className="icon" />
                  <h3 className="sf animate-pulse">Safe</h3>
                  <p className="txt">
                    Investment in assets with low volatility, such as
                    real-estate or blue-chip stocks, can provide a stable
                    long-term return on investment
                  </p>
                </div>
                <div className="section">
                  <FaShieldAlt className="icon" />
                  <h3 className="sf animate-pulse">Security</h3>
                  <p className="txt">
                    Investment can provide a sense of security by creating a
                    reliable stream of income through dividends, interest
                    payments, or rental income.
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="section">
                  <FaChartLine className="icon" />
                  <h3 className="sf animate-pulse">Return</h3>
                  <p className="txt">
                    Investing in real estate can provide tax benefits, such as
                    deductions for mortgage interest and property taxes, further
                    increasing potential returns.
                  </p>
                </div>
                <div className="section">
                  <FaMoneyBillWave className="icon" />
                  <h3 className="sf animate-pulse">Cash</h3>
                  <p className="txt">
                    By investing in assets that produce regular income, such as
                    rental properties or dividend-paying stocks, you can create
                    a steady cash flow stream that can be reinvested or used to
                    cover expenses.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <h2 className="sp mb-2">
            <span className="text-black">Movers pack lives,</span> not just
            boxes 🚚
          </h2>
        </div>
        <div className="electriccontainer mb-10">
          <div className="electriccontent">
            <h2 className="text-4xl font-bold text-start text-[#005978] mt-2 ">
              Movers & Packers!
            </h2>
            <p className=" text-lg font-semibold">
              Good movers and packers don't just move your belongings, they move
              your life.
            </p>
            <Link to="/move-packers">
              <button className="homebutton mt-8">Book now</button>
            </Link>
          </div>
          <div className="electricimage">
            <img className="imm" src={movers} alt="movers"></img>
          </div>
        </div>
        <div>
          <h2 className="pro">***See How Property10X can help***</h2>
        </div>
        <div class="card-container">
          <div className="card">
            <Link to="/allprojects">
              <img src={Prorent} alt=" " />
              <div className="card-content">
                <h3 className="text-[#005978] font-semibold">Rent a home</h3>
                <p>
                  Looking for a new place to call home? Browse our selection of
                  rental properties and find the perfect fit for you with ease.
                  Rent a home today!
                </p>
              </div>
            </Link>
          </div>
          <div className="card">
            <Link to="/allprojects">
              <img src={Probuy} alt="" />
              <div className="card-content">
                <h3 className="text-[#005978] font-semibold">Buy a home</h3>
                <p>
                  Looking to become a homeowner? Discover your dream home with
                  our wide range of properties for sale. Browse our selection
                  and find the perfect fit for you. Buy your home today!
                </p>
              </div>
            </Link>
          </div>

          <div className="card">
            <Link to="/allprojects">
              <img src={Propro} alt="Card Imagee" />
              <div className="card-content">
                <h3 className="text-[#005978] font-semibold">
                  Let's see the Projects
                </h3>
                <p>
                  Looking for your next big project? Check out our portfolio of
                  exciting projects in various fields, Explore our projects
                  today!
                </p>
              </div>
            </Link>
          </div>
          <div className="card">
            <Link to="/contactus">
              <img src={exp} alt="Card Imagee" />
              <div className="card-content">
                <h3 className="text-[#005978] font-semibold">Explore Now</h3>
                <p>
                  Ready to discover more? Explore now and find out what we have
                  in store for you. Don't wait, start exploring today!
                </p>
              </div>
            </Link>
          </div>
        </div>
        <div className="container explore">
          <div className="text-container start">
            <h1 className="ss">
              <Link to="/contactus" className="link add">
                <span className="text-black">Get a</span> Callback
              </Link>
            </h1>
            <p className="ex">
              "Wait for a Callback soon..." -
              <span className="text-[#005978] text-xl "> Property10X</span>
            </p>
            <button className="button but animate-bounce">
              <Link to="/contactus" className="button-link">
                Enquire now
              </Link>
            </button>
          </div>
          <div className="image-container ">
            <img className="simhh" src={giff} alt="Exploring" />
          </div>
        </div>
        <div className="home-loan-headerr mt-10">
          <h1>
            Home Loan{" "}
            <p className="">
              "Securing a home loan is the first step towards building a
              foundation of wealth, providing both financial stability and
              emotional security."
            </p>
          </h1>
          <Link to="/emi">
            <button className=" bbb">Click here</button>
          </Link>
        </div>
        <Cityy />
        <Why />
      </div>
      {openModal && (
        <Modal>
          {" "}
          <ShowModal onHandleClose={onHandleClose} />{" "}
        </Modal>
      )}
    </>
  );
};
export default Hero;
