/* eslint-disable no-unused-vars */
import { useState ,useEffect } from "react";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import { Link } from "react-router-dom";
import OAuth from "../components/OAuth";
import {
  getAuth,
  createUserWithEmailAndPassword,
  updateProfile,
  PhoneAuthCredential,
} from "firebase/auth";  
import { db } from "../firebase";
import { doc, serverTimestamp, setDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export default function SignUp() {
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }, [])
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    password: "",
  });
  const [phoneNumber, setPhoneNumber] = useState();
  const [error, setError] = useState('');

  const { name, email, password ,phone } = formData;
  const navigate = useNavigate();
  const onPhoneChange = (e) => {
    setPhoneNumber(e.target.value);
  };
  function onChange(e) {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  }
  async function onSubmit(e) {
    e.preventDefault();
  
    try {
      const auth = getAuth();
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
    
      const userData = {
        email: userCredential.user.email,
        phone: phoneNumber,
        name: name,
        timestamp: serverTimestamp()
      };
  
      await setDoc(doc(db, "users", userCredential.user.uid), userData);
  
      await updateProfile(auth.currentUser, {
        displayName: name,
      });
      toast.success("Sign up was successful");
      navigate("/");
    } catch (error) {
      console.log(error);
      toast.error( `${error.message}`);
    }
  }
  return (
    <section className="bg-[#d4ecf5]">
    <div className="flex justify-center flex-wrap items-center px-6 py-12 max-w-6xl mx-auto">
      <div className="w-full md:w-[67%] lg:w-[40%] lg:ml-20">
        <h1 className="text-3xl text-center text-[#005978] font-bold mb-3">Sign Up</h1>
        <form onSubmit={onSubmit} className="bg-white rounded-lg shadow-md px-8 py-6">
          <input
            type="text"
            id="name"
            value={name}
            onChange={onChange}
            placeholder="Full name"
            required
            className="mb-6 w-full px-4 py-2 text-xl text-gray-700 bg-gray-100 border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-600"
          />
          <input
            type="text"
            id="phoneNumber"
            value={phoneNumber}
            onChange={onPhoneChange}
            required
            placeholder="Mobile No."
            className="mb-6 w-full px-4 py-2 text-xl text-gray-700 bg-gray-100 border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-600"
          />
          <input
            type="email"
            id="email"
            value={email}
            onChange={onChange}
            placeholder="Email address"
            required
            className="mb-6 w-full px-4 py-2 text-xl text-gray-700 bg-gray-100 border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-600"
          />
          <div className="relative mb-6">
            <input
              type={showPassword ? "text" : "password"}
              id="password"
              value={password}
              onChange={onChange}
              placeholder="Password should be at least 5 characters"
              required
              className="w-full px-4 py-2 text-xl text-gray-700 bg-gray-100 border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-600"
            />
              {showPassword ? (
              <AiFillEyeInvisible
                className="absolute right-3 top-3 text-xl cursor-pointer text-gray-500 hover:text-gray-700"
                onClick={() => setShowPassword((prevState) => !prevState)}
              />
            ) : (
              <AiFillEye
                className="absolute right-3 top-3 text-xl cursor-pointer text-gray-500 hover:text-gray-700"
                onClick={() => setShowPassword((prevState) => !prevState)}
              />
            )}
          </div>
          <div className="flex flex-col md:flex-row md:justify-between text-sm sm:text-lg mb-6">
            <p className="mb-2 md:mb-0">
              Have an account?{" "}
              <Link
                to="/sign-in"
                className="text-red-600 hover:text-red-700 transition duration-200 ease-in-out"
              >
                Sign in
              </Link>
            </p>
            <p>
              <Link
                to="/forgot-password"
                className="text-blue-600 hover:text-blue-800 transition duration-200 ease-in-out"
              >
                Forgot password?
              </Link>
            </p>
          </div>
          <button
            className="w-full bg-blue-600 text-white px-7 py-3 text-sm font-medium uppercase rounded shadow-md hover:bg-blue-700 transition duration-150 ease-in-out hover:shadow-lg"
            type="submit"
          >
            Sign up
          </button>
          <div className="flex items-center my-4 before:border-t before:flex-1 before:border-gray-300 after:border-t after:flex-1 after:border-gray-300">
            <p className="text-center font-semibold mx-4">OR</p>
          </div>
          <OAuth />
        </form>
      </div>
    </div>
  </section>
  
  
  );
}
