import React, { useEffect } from "react";
import { useState } from "react";
import "./Aboutus.css";
import Waseem from "../assets/svg/waseem.jpeg";
import Shaily from "../assets/svg/shaily copy.jpeg";
// import Chiranjeev from "../assets/svg/chiranjeev.jpeg";
import nikhil from "../assets/svg/nikhil.jpg";
import { FaLinkedinIn } from "react-icons/fa";
import { BsFacebook } from "react-icons/bs";
import { FiInstagram } from "react-icons/fi";
import { BsTwitter } from "react-icons/bs";
import ReactGA from "react-ga";
const TRACKING_ID = "G-P4PT1KJLDT";
ReactGA.initialize(TRACKING_ID);
function initializeReactGA() {
  ReactGA.initialize("G-P4PT1KJLDT");
  ReactGA.pageview(window.location.pathname + window.location.search);
}
const AboutUs = () => {
  useEffect(() => {
    initializeReactGA();
  }, []);
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }, []);
  const [email, setEmail] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(`Subscribing email address: ${email}`);
    setEmail("");
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };
  return (
    <div className="about-us display-copy">
      <div className="description">
        <h1>About Our Company</h1>
        <p>
          Property 10X is a real estate company that aims to help individuals
          and organizations achieve financial freedom through property
          investment. The company offers a range of services including property
          sales, property management, and property investment training & We
          offer a 360° solution for Real-Estate industry through our Learn,
          Collaborate and Earn Model. We believe in creating entrepreneurs and
          providing an innovative ecosystem to leverage the power of the Indian
          Real-Estate industry & providing opportunities for creating wealth.
        </p>
      </div>
      <h2 className="Meet">Meet Our Creators</h2>
      <div className="container fondcont ">
        <div className="box fonbox">
          <img className="fonimg" src={Waseem} alt=" Waseem" />
          <h3 className="fonh">Waseem Akram</h3>
          <p className="detail">CEO & Founder</p>
          <p>
            He has more than 10+ years of experience in the real estate industry
            and startup ecosystem and He is a real estate professional.
            Proficient Business Leader from KPMG and Certified from Australian
            Deccan University.
          </p>
          <div className="social-media">
            <a href="https://www.linkedin.com/in/waseem-akram-851385151/ ">
              <i className="FaLinkedinIn"></i>
              <FaLinkedinIn />
            </a>
            <a href=" # ">
              <i className="FiInstagram"></i>
              <FiInstagram />
            </a>
            <a href=" # ">
              <i className="BsFacebook"></i>
              <BsFacebook />
            </a>
            <a href=" # ">
              <i className="BsTwitter"></i>
              <BsTwitter />
            </a>
          </div>
        </div>
        <div className="box fonbox">
          <img className="fonimg" src={Shaily} alt="Shaily" />
          <h3 className="fonh">Shaily Madaan</h3>
          <p className="detail">CO-FOUNDER</p>
          <p>
            She has 5+ year of experience in real estate industry and known for
            her expertise and her commitment to providing personalized service
            have earned her a reputation as a trusted and reliable real estate
            professional.
          </p>
          <div className="social-media">
            <a href=" # ">
              <i className="FaLinkedinIn"></i>
              <FaLinkedinIn />
            </a>
            <a href=" # ">
              <i className="FiInstagram"></i>
              <FiInstagram />
            </a>
            <a href=" # ">
              <i className="BsFacebook"></i>
              <BsFacebook />
            </a>
            <a href=" # ">
              <i className="BsTwitter"></i>
              <BsTwitter />
            </a>
          </div>
        </div>
        {/* <div className="box fonbox">
          <img className='fonimg' src={Chiranjeev} alt="Chiranjeev" />
          <h3 className='fonh'>Chirranjiev Khanna</h3>
          <p className='detail'>CO-FOUNDER</p>
          <p>He is a leading trainer and mentor in Finance and Investments domain having 11+ years of experience in Financial markets and Real Estate Investments. He create Realpreneurs with innovative ecosystem of Property10X.</p>
          <div className="social-media">
            <a href=" # ">
              <i className="FaLinkedinIn"></i>
              <FaLinkedinIn/>
            </a>
            <a href=" # ">
            <i className="FiInstagram"></i>
            <FiInstagram/>
          </a>
          <a href=" # ">
          <i className="BsFacebook"></i>
          <BsFacebook/>
        </a>
        <a href=" # ">
        <i className="BsTwitter"></i>
        <BsTwitter/>
      </a>
          </div>
        </div> */}
      </div>
      <div className="flex items-center lg:justify-between flex-col lg:flex-row mt-8 lg:mt-4 m-20">
        <div className="flex flex-col mt-4 lg:mt-0">
          <span className="text-3xl text-[#005978] font-bold text-center lg:text-left">
            6000+
          </span>
          <span className="text-s font-semibold">Strong Investor Base</span>
        </div>

        <div className="hidden lg:block border-l-2 border-[#005978] h-12 my-auto mx-8"></div>

        <div className="flex flex-col mt-8 lg:mt-0">
          <span className="text-3xl text-[#005978] font-bold text-center lg:text-left">
            10 Years+
          </span>
          <span className="text-s font-semibold text-center">
            of Valuable industry experience
          </span>
        </div>

        <div className="hidden lg:block border-l-2 border-[#005978] h-12 my-auto mx-8"></div>

        <div className="flex flex-col mt-8 lg:mt-0">
          <span className="text-3xl text-[#005978] font-bold text-center lg:text-left">
            150 Cr+
          </span>
          <span className="text-s font-semibold text-center">
            Worth high-value transaction
          </span>
        </div>
      </div>
      <div className="quote">
        <blockquote>
          "At our company, we believe that real estate is not just about buying,
          selling, or renting properties. It's about creating new beginnings and
          building a better future for our clients."
        </blockquote>
      </div>
      <div className="employees">
        <h2 className="Team">Our Strength</h2>
        <p className="Des">
          Our team is our greatest strength. Each of our employees is a highly
          skilled and experienced professional, dedicated to providing the best
          possible service to our clients & they are passionate about what they
          do, and this is reflected in the exceptional work they deliver. We
          value the expertise and unique perspectives that each team member
          brings to the table, and we believe that this diversity is one of our
          greatest assets.
        </p>
        <div class="employee-container">
          <div class="employee">
            <div class="employee-image">
              <img src={nikhil} alt="nikhil" />
            </div>
            <h3>Nikhil Chauhan</h3>
            <p className="text-black">Business Deveopment Executive</p>
            <div class="social-media-icons">
              <a
                href="https://www.linkedin.com/in/1praveenkumar/"
                target="_blank"
                rel="noreferrer"
              >
                <i class="fab fa-linkedin"></i>
              </a>
              <a
                href="https://www.instagram.com/praveenchats/"
                target="_blank"
                rel="noreferrer"
              >
                <i class="fab fa-instagram"></i>
              </a>
              <a href=" " target="_blank">
                <i class="fab fa-facebook"></i>
              </a>
              <a href=" " target="_blank">
                <i class="fab fa-twitter"></i>
              </a>
            </div>
          </div>
        </div>
        <div className="soc">
          Our Social Media Handle
          <div className="social-media">
            <a
              href=" https://www.linkedin.com/company/property-10x/"
              target="_blank"
            >
              <i className="FaLinkedinIn"></i>
              <FaLinkedinIn />
            </a>
            <a
              href=" https://instagram.com/property__10x?igshid=MWI4MTIyMDE= "
              target="_blank"
            >
              <i className="FiInstagram"></i>
              <FiInstagram />
            </a>
            <a
              href=" https://www.facebook.com/theproperty10x?mibextid=LQQJ4d"
              target="_blank"
            >
              <i className="BsFacebook"></i>
              <BsFacebook />
            </a>
            <a href=" # ">
              <i className="BsTwitter"></i>
              <BsTwitter />
            </a>
          </div>
        </div>
        <div className="subscribe">
          <form onSubmit={handleSubmit}>
            <input
              type="email"
              placeholder="Enter your email address"
              value={email}
              onChange={handleEmailChange}
            />
            <button type="submit">Subscribe</button>
          </form>
        </div>
      </div>
    </div>
  );
};
export default AboutUs;
