import React, { useState } from 'react';
import './PropertyForm.css'; // Import your CSS file

function PropertyForm() {
  const [principal, setPrincipal] = useState('');
  const [interestRate, setInterestRate] = useState('');
  const [years, setYears] = useState('');
  const [result, setResult] = useState(null);

  const handlePrincipalChange = (event) => {
    setPrincipal(event.target.value);
  };

  const handleInterestRateChange = (event) => {
    setInterestRate(event.target.value);
  };

  const handleYearsChange = (event) => {
    setYears(event.target.value);
  };

  const calculateInterest = () => {
    const principalAmount = parseFloat(principal);
    const interestRateValue = parseFloat(interestRate) / 100;
    const yearsValue = parseFloat(years);

    const finalAmount = principalAmount * (1 + interestRateValue * yearsValue);
    setResult(finalAmount.toFixed(2));
  };

  return (
    <div className="property-form-container">
      <h1 className="calculator-title">Interest Rate Calculator</h1>
      <div className="input-group">
        <label>Principal Amount:</label>
        <input
          type="text"
          value={principal}
          onChange={handlePrincipalChange}
        />
      </div>
      <div className="input-group">
        <label>Interest Rate (as a percentage):</label>
        <input
          type="text"
          value={interestRate}
          onChange={handleInterestRateChange}
        />
      </div>
      <div className="input-group">
        <label>Number of Years:</label>
        <input
          type="text"
          value={years}
          onChange={handleYearsChange}
        />
      </div>
      <button className="calculate-button" onClick={calculateInterest}>
        Calculate
      </button>
      {result !== null && (
        <div className="result">
          <h2>Final Amount:</h2>
          <p>${result}</p>
        </div>
      )}
    </div>
  );
}

export default PropertyForm;
