/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
import { RiSearchLine } from 'react-icons/ri';
import { Link, useNavigate } from 'react-router-dom';
import {
  collection,
  getDocs,
  query,
  where,
  orderBy
} from 'firebase/firestore';
import { db } from '../firebase';  
import HeroBtn from './HeroBtn';
import houses from '../assets/svg/lanscape.jpeg';
import Fuse from 'fuse.js';

const Search = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [maxVisibleResults, setMaxVisibleResults] = useState(5); // Number of visible results
  const searchContainerRef = useRef(null);
  const navigate = useNavigate();   
  
  const buttons = [
    { title: 'BUY', underline: 'bg-[#e20112]', route: '/buy' },
    { title: 'RENT', route: '/rent' },
    { title: 'SELL', route: '/sell' },
    { title: 'PROJECTS', route: '/allprojects' },
    { title: 'TRAINING', route: '/Training' },
  ];
  const SearchResult = ({ result }) => {
    const { id, Name, ProjectType } = result;
    const projectLink = `/project/${ProjectType}/${id}`;
    const residentialProjectLink = `/residentialproject/${ProjectType}/${id}`;
    useEffect(() => {
      const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
          handleSearch();
        }
      };
  
      document.addEventListener('click', handleOutsideClick);
      document.addEventListener('keypress', handleKeyPress);
  
      return () => {
        document.removeEventListener('click', handleOutsideClick);
        document.removeEventListener('keypress', handleKeyPress);
      };
    }, []);
    return (
      <div key={id} className="bg-white py-1 w-[19rem] sm:w-[34rem] pl-5 placeholder:text-gray-500 placeholder:text-[20px] outline-0">
        {ProjectType === 'Residential' ? (
          <Link to={residentialProjectLink}>
            <h3 className="text-l font-bold mt-2 text-[#005978] hover:text-red-800 ">
              {Name}-(Residential)
            </h3>
            {/* Add other relevant residential project details here */}
          </Link>
        ) : (
          <Link to={projectLink}>
            <h3 className="text-l font-bold mt-1 text-[#005978] hover:text-red-800 ">
              {Name}-(Commercial)
            </h3>
            {/* Add other relevant project details here */}
          </Link>
        )}
      </div>
    );
  };
  
  const renderButtons = () => {
    return buttons.map((button, index) => (
      <HeroBtn
        key={index}
        title={button.title}
        underline={button.underline}
        css={button.css}
        route={button.route}
      />
    ));
  };

  const handleSearch = async () => {
    console.log('Search query:', searchQuery);
   
    if (searchQuery.toLowerCase() === 'commercial') {
      navigate('/commercialprojects');
      return;
    } else if (searchQuery.toLowerCase() === 'residential') {
      navigate('/residentialprojects');
      return;
    } else if (searchQuery.toLowerCase() === 'noida') {
      navigate('/allprojects');
      return;
    }

    try {
      // Query for both 'projects' and 'ResidentialProjects' collections
      const projectsQuery = query(
        collection(db, 'projects'),
        orderBy('timestamp', 'desc')
      );

      const residentialProjectsQuery = query(
        collection(db, 'ResidentialProjects'),
        orderBy('timestamp', 'desc')
      );

      // Fetch data from both collections
      const projectsSnapshot = await getDocs(projectsQuery);
      const residentialProjectsSnapshot = await getDocs(residentialProjectsQuery);

      // Combine results from both collections
      const results = [];
      projectsSnapshot.forEach((doc) => {
        const data = doc.data();
        results.push({ id: doc.id, ...data });
      });
      residentialProjectsSnapshot.forEach((doc) => {
        const data = doc.data();
        results.push({ id: doc.id, ...data });
      });

      // Perform fuzzy search on combined results
      const fuse = new Fuse(results, {
        keys: ['Name', 'Location', 'ProjectType', 'Price'], // Search in these fields
        threshold: 0.3, // Adjust the threshold for matching
      });

      const fuzzyResults = fuse.search(searchQuery);
      setSearchResults(fuzzyResults.map((result) => result.item));
    } catch (error) {
      console.log(error);
    }
  };

  const handleOutsideClick = (e) => {
    if (searchContainerRef.current && !searchContainerRef.current.contains(e.target)) {
      setSearchResults([]); // Clear search results if clicked outside the search area
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  return (
    <div className="h-[25rem] sm:h-[27rem] flex bg-red-400 overflow-hidden">
      <div className="absolute w-full">
        <div className="bg-gray-500/10 absolute w-full h-[25rem] opacity-2"></div>
        <img
          src={houses}
          alt="Houses"
          className="object-cover w-full h-[25rem] sm:h-[27rem]"
        />
      </div>
      <div className="relative flex flex-col items-center w-full pt-12">
        <p className="text-[#005978] text-[32px] sm:text-[55px] font-bold">
          Each to their own abode.
        </p>
        <p className="text-white mt-6 pt-2 text-[20px] sm:pt-0 sm:text-[25px] lg:mt-12">
          Let's discover the ideal home for you.
        </p>
        <ul>
          <div className="pt-12 flex space-x-3 sm:space-x-6">{renderButtons()}</div>
        </ul>
        {/* Input */}
        <div className="relative mt-9" ref={searchContainerRef}>
          <input
            type="search"
            className="bg-white py-7 w-[22rem] sm:w-[37rem] rounded-full pl-7 placeholder:text-gray-500 placeholder:text-[20px] outline-0"
            placeholder="Address, Projects, City, Zip"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <div
            className="absolute w-[2.8rem] h-[2.8rem] rounded-full bg-[#005978] top-[0.6rem] right-1 flex items-center justify-center cursor-pointer"
            onClick={handleSearch}
          >
            <RiSearchLine className="text-white text-[22px]" />
          </div>
        </div>
        {/* Display search results */}
        <div className="z-100 -mt-6 overflow-y-auto max-h-[20rem]">
        {searchResults.slice(0, maxVisibleResults).map((result) => (
          <SearchResult key={result.id} result={result} />
        ))}
      </div>
      {searchResults.length > maxVisibleResults && (
        <div className="mt-4 flex justify-center">
          <button
            className="text-white hover:underline mb-4"
            onClick={() => setMaxVisibleResults(maxVisibleResults + 5)} // Increase the number of visible results
          >
            Load More
          </button>
        </div>
      )}
      </div>
    </div>
  );
};
export default Search;
