/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { MdOutlineAddLocationAlt } from 'react-icons/md';
import { FaTrash } from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import { Link } from 'react-router-dom';
import './Listingprojectstyle.css';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faHeart, faShareAlt } from '@fortawesome/free-solid-svg-icons';
import  Modal  from "../components/Modal";
import ShowModal from "../components/ShowModal"
function Listingproject({ listing, id , isLoggedIn ,onEditd, onDeleted ,onEditdd, onDeletedd}) {
  const [wishlist, setWishlist] = useState([]);
  const handleBrochureClick = (e) => {
    e.preventDefault();
    window.open(listing.Brochure[0], '_blank');
  };
  const handleWishlistClick = (e) => {
    e.preventDefault();
    const index = wishlist.findIndex((item) => item.id === listing.id);
    if (index === -1) {
      setWishlist((prevWishlist) => [...prevWishlist, listing]);
      e.currentTarget.classList.add('active');
    } else {
      const updatedWishlist = wishlist.filter((item) => item.id !== listing.id);
      setWishlist(updatedWishlist);
      e.currentTarget.classList.remove('active');
    }
  };
  const handleShareClick = (e) => {
    e.preventDefault();
    if (navigator.share) {
      navigator.share({
        title: listing.Title,
        url: listing.shareUrl,
      });
    } else {
      window.open(listing.shareUrl, '_blank');
    }
  };
    //ModalConcept
    const [openModal, setOpenModal] = useState(false);
    const onHandleClick =()=>{
    setOpenModal(true)
    }
    const onHandleClose =() =>{
      setOpenModal(false)
    }

return (
    <>
      <div className="listing-card">
      <Link className="contents" to={`/project/${listing.ProjectType}/${id}`}>
      <div className="listing-image-container">
  <div className="listing-text-box">
    <a href="/sign-in" onClick={(e) => { e.stopPropagation(); }}>
      Download Brochure
    </a>
  </div>
  <img className="listing-image" src={listing.imgUrls[0]} alt="h" />
  <div className="listing-image-icons">
    <button
      className={`listing-wishlist-icon${wishlist.some((item) => item.id === listing.id) ? ' active' : ''}`}
      onClick={handleWishlistClick}
    >
      <FontAwesomeIcon icon={faHeart} />
    </button>
    <button className="listing-share-icon" onClick={handleShareClick}>
      <FontAwesomeIcon icon={faShareAlt} />
    </button>
  </div>
</div>
        </Link>
        <div className="listing-details">
          <div className="listing-footer">
            <div className="listing-name">{listing.Name}</div>
            <div className="listing-wh">
              <a
                href="https://api.whatsapp.com/send/?phone=919871424020&text&type=phone_number&app_absent=0"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i
                  className="fa fa-whatsapp whatsapp-icon"
                  style={{
                    fontSize: '25px',
                    border: '2px solid green',
                    padding: '1px',
                  }}
                ></i>
              </a>
            </div>
          </div>
        </div>
  
        <div className="listing-footer">
          <div className="listing-location">
            <MdOutlineAddLocationAlt />
            <span>{listing.Location}</span>{' '}
          </div>
          <div className="listing-status">{listing.Status}</div>
        </div>
        <div className="listing-footer">
          <div className="listing-price">{listing.Price}</div>
          <div className="listing-type">{listing.ProjectType}</div>
        </div>

        <div className="listing-actions">
          <Link
            to={`/project/${listing.ProjectType}/${id}`}
          >
            <Button
              type="submit"
              className="enquire"
              style={{ backgroundColor: '#005978', color: '#ffffff' }}
            >
              Start Investing!
            </Button>
          </Link>

          <div className="listing-brochure" onClick={onHandleClick}  >
  <div className="brochure-container">
    <FontAwesomeIcon icon={faDownload} className="pdf-icon" color="red" />
    Download Brochure
  </div>
</div>  
        </div>
        <div className="flex">
  {onDeleted && (
    <FaTrash
      className="mt-5 cursor-pointer text-red-500"
      onClick={() => onDeleted(listing.id)}
    />
  )}
  {onEditd && (
    <MdEdit
      className="mt-5 ml-5 cursor-pointer text-red-500"
      onClick={() => onEditd(listing.id)}
    />
  )}
</div>

<div className="flex">
  {onDeletedd && (
    <FaTrash
      className="mt-5 cursor-pointer text-red-500"
      onClick={() => onDeletedd(listing.id)}
    />
  )}
  {onEditdd && (
    <MdEdit
      className="mt-5 ml-5 cursor-pointer text-red-500"
      onClick={() => onEditdd(listing.id)}
    />
  )}
</div>
     </div> 
    {openModal && <Modal> <ShowModal onHandleClose={onHandleClose}/>  </Modal>}
    </>
  );
}
export default Listingproject;
