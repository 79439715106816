import React from "react";
import "./Why.css";
import best from "../assets/svg/best-price.png";
import trust from "../assets/svg/trust.png";
import work from "../assets/svg/work-process.png";
import clock from "../assets/svg/clock.png";
const Why = () => {
  return (
    <div>
    <div><h2 className="whyy"><span className="text-black">Our commitment to meeting</span> <span className="text-[#005978]"> your needs.</span></h2>
    <p className=" text-center mb-10">Buying from us guarantees access to a wide range of high-quality properties that meet your specific needs and budget.</p></div>
    <div className="headlines">
      <div className="headline trust">
        <img src={trust} alt=""></img>
        <h2>Trust Worthy</h2>
        <p>We value your trust and take it seriously. That's why we make sure to provide only the highest quality products and services.</p>
      </div>
      <div className="headline best">
        <img src={best} alt=""></img>
        <h2>Best Value</h2>
        <p>Our products and services are not only high quality, but also affordable. We believe that everyone should have access to the best.</p>
      </div>
      <div className="headline quick">
        <img src={clock} alt=""></img>
        <h2>Super Quick</h2>
        <p>Our fast and efficient services are designed to save you time and hassle. You can count on us to get the job done quickly.</p>
      </div>
      <div className="headline unique">
        <img src={work} alt=""></img>
        <h2>Unique Process</h2>
        <p>We take a unique approach to everything we do. Our process is designed to ensure that you get the best results possible.</p>
      </div>
      </div>
    </div>
  );
};
export default Why;