import React from 'react'
import {
  collection,
  getDocs,
  orderBy,
  query,
} from "firebase/firestore";
import { useEffect } from "react";
import { useState } from "react";
import { db } from "../firebase";
import ResidentialListingProjects from "../components/ResidentialListingProjects"

function Projects() {
    useEffect(() => {
        setTimeout(() => {
          window.scrollTo(0, 0);
        }, 100);
      }, []);
    const [projects, setProjects] = useState(null);
    useEffect(() => {
      async function fetchListings() {
        try {
          const listingsRef = collection(db, "ResidentialProjects");
          const q = query(
            listingsRef,
            orderBy("timestamp", "desc"),
          );
          const querySnap = await getDocs(q);
          const plistings = [];
          querySnap.forEach((doc) => {
            return plistings.push({
              id: doc.id,
              data: doc.data(),
            });
          });
          setProjects(plistings);
        }
  catch (error) {
          console.log(error);
        }
      }
      fetchListings();
    }, []);
  
  return (
    <div>
      <div className="max-w-6xl mx-auto pt-4 space-y-6 cards">
              {projects  && (
              <div className="m-2 mb-6 text-center">
                <p className="text-2xl font-semibold capitalize text-headingColor relative before:absolute before:rounded-lg before:content before:w-32 before:h-1 before:-bottom-2 before:left-0 transition-all ease-in-out duration-100">
            Residential Projects
          </p>
                <section className='mb-20'>
                  <div className='container mx-auto'>
                <div className='grid md:grid-cols-2 lg:grid-cols-3 gap-4 lg:gap-14'>
                  {projects.map((listing) => (
                    <ResidentialListingProjects
                    key = {listing.id}
                    listing = {listing.data} 
                    id = {listing.id}                  
                    />
                  ))}          
                </div>  
                </div>              
                </section>
                </div>              
            )}
    </div>
    </div>
  )}

export default Projects