/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState } from 'react';
import { useParams } from 'react-router';
import { useEffect } from 'react';
import './Commercial.css';
import Spinner from '../components/Spinner';
import Why from  "../components/Why";
import { FaRegHeart, FaHeart, } from 'react-icons/fa';
import {doc, getDoc} from 'firebase/firestore';
import {db} from '../firebase'
import Slider from "react-slick"; 
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  prevArrow: <FaChevronLeft className="slick-arrow slick-prev"/>,
  nextArrow: <FaChevronRight className="slick-arrow slick-next" />,
};
const Residential = () => {
  const [isWishlist, setIsWishlist] = useState(false);
  const handleWishlistClick = () => {
    setIsWishlist(!isWishlist);
  };
  const params = useParams();
  const [listing, setListing] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    
  async function fetchListing() {
    const docRef = doc(db, "ResidentialProjects", params.projectId);

    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
    
      setListing(docSnap.data());
      setLoading(false);
    }
  }
  fetchListing();
}, [params.projectId]);
if (loading) {
  return <Spinner />;
}
  return (
    <>
    <div className="property-card">
    <div className="property-image">
  <Slider {...settings}>
    {listing.imgUrls.map(url => (
      <img key={url} src={url} alt="Property" />
    ))}
  </Slider>
  <button className="wishlist-button" onClick={handleWishlistClick}>
          {isWishlist ? <FaHeart color="red" /> : <FaRegHeart />}
        </button></div>
        <div className="property-details">
        <div className="name"><h2>{listing.Name} <span className='text-[#808080] text-xl'>{listing.Location}</span></h2></div>
        <h2 className="property-price">
        {listing.Price}
        </h2>
        <div className="property-info">
          <p>
            Status: <span>{listing.Status}</span>
          </p>
          <p>
            Location: <span>{listing.Location}</span>
          </p>
         
          <p>
            Configuration: <span>{listing.Configurations}</span>
          </p>
          <p>
            Project Size: <span>{listing.ProjectSize}</span>
          </p>
          <p>
            RERA Reg.: <span>{listing.ReraNumber}</span>
          </p>

        </div>
        <p className=' mt-8'>
  <a href="/Contact-us" className="button ca shake animate-bounce">
    <i className="p-2 fas fa-headset"></i> Request a Call Back
  </a>
</p>
</div>
    </div>
    <div><h2 className='sp lg:text-center'>{listing.Name} - Project Info</h2>
        <p className=' lg:text-center mt-3 px-4 py-4'>{listing.ProjectInfo}</p></div>
          <div><h2 className='sp lg:text-center'>Why {listing.Name} <span className='text-[#c95128]'>?</span></h2></div>
          <div style={{ padding: "0rem", maxWidth: "60rem", margin: "auto", marginTop: "2rem" }}>
  {/* <h2 style={{ textAlign: "center", marginBottom: "2rem", color: "#c95128", fontSize: "3rem" }}>Why This Project</h2> */}
  <ul style={{ listStyle: "none", margin: "0", padding: "0" }}>
    {listing.WhyThisProject.map((element, index) => (
      <li key={index} style={{ marginBottom: "1rem", paddingLeft: "2.5rem", position: "relative" }}>
        <span style={{ position: "absolute", left: "20px", top: "0", transform: "translate(-1rem, 0)", display: "inline-flex", alignItems: "center", justifyContent: "center", backgroundColor: "#005978", color: "#fff", borderRadius: "0.5rem", width: "2rem", height: "2rem", fontSize: "1.5rem" }}>➤</span>
        <span style={{ fontSize: "1rem", paddingLeft: "2.5rem" }}>{element.service}</span>
      </li>
    ))}
  </ul>
</div>
<div className='specify'><h2 className='sp mb-8'>{listing.Name} - Price List<span className='text-black'>.</span></h2></div>
    <div>
  <table>
    <thead>
      <tr>
        <th>Unit Type</th>
        <th>Area</th>
        <th>New Home Price</th>
      </tr>
    </thead>
    {listing.PriceList.map((element,index)=>(
    <tbody key={index}>
      <tr>
        <td className='text-[#c95128] font-semibold'>{element.Area}</td>
        <td>{element.UnitType}</td>
        <td>{element.NewHomePrice}</td>
      </tr>
    </tbody>
    ))}
  </table>
    </div>
    <div><h2 className='sp'>{listing.Name} - Floor Plan </h2><p className='lg:text-center mt-3 px-4'>{listing.FloorDescription}</p>
    <div className='ml-6 mt-6  lg:ml-[38rem]'><a href="/Contact-us" className=" button ca shake animate-bounce">
  <i className=" p-2 fas fa-phone "></i>Office Space BHK</a></div></div>
  <div></div>
  <div className='brou'>
  <h2 className='sp'>{listing.Name} - Brochure</h2>
  <iframe id="my-iframe" src={listing.Brochure[0]} type="application/pdf" width="800" height="100%" sandbox></iframe>
</div>

<div><h2 className='sp'>{listing.Name} Video</h2>
  <div className="video-section">
      <video src={listing.ProjectVideo[0]} controls />
    </div>
</div> 
  <div><h1 className='sp'>Amenities.</h1>
    <p className="lg:text-center px-4">Amenities refer to the facilities and services that are provided in a particular setting to enhance the comfort and convenience of the people using them.</p></div>
    <div className='am'>
      {listing.Amenities.map((amenity, index) => (
        <div key={index} className='am'>
          <div className='amenity'>
            <img src={amenity.image} alt={amenity.label} />
            <p>{amenity.label}</p>
          </div>
        </div>
      ))}
      
    </div>
    {listing.NearByInfra.length > 0 &&
  <div>
    <h1 className='sp'>Nearby Infra</h1>
    <p className="lg:text-center px-4">Living near good infrastructure can save you time and money by reducing your commute time, making it easier to access essential services like healthcare and education, and increasing the overall convenience and quality of life in your community.</p>
    <div className='am'>
      {listing.NearByInfra.map((amenity, index) => (
        <div key={index} className='am'>
          <div className='amenity'>
            <img src={amenity.image} alt={amenity.label} />
            <p>{amenity.label}</p>
            <p>{amenity.Description}</p>
          </div>
        </div>
      ))}
    </div>
  </div>
}
<div><h2 className='sp'>{listing.Name} - Location Map & Landmarks</h2>
      <div className="map-container">
      <iframe
        title="Google Maps"
        src={`https://maps.google.com/maps?q=${listing.Location}&t=&z=13&ie=UTF8&iwloc=&output=embed`}
        allowFullScreen/>
    </div>
    </div>
    <div><h2 className='sp'>Bhutani Group Projects</h2></div>
    <div className="bhutani-projects-container">
      <div className="bhutani-logo-container">
        <img src={listing.BuilderLogo} alt="Bhutani Group logo" className="bhutani-logo" />
      </div>
      <div className="bhutani-projects-info">
        <div className="bhutani-data">
          <h2>Total Projects : <span className='expe'>{listing.BuilderTotalProjects}</span></h2>
     <p>{listing.BuilderDescription}</p>
     <h2>Experience : <span className='expe'>{listing.BuilderExperience}</span></h2>
        </div>
      </div>
    </div>
     <Why/> </>
  );
};
export default Residential;