/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/img-redundant-alt */
import './Interior.css';
import React, { useState ,useEffect } from 'react';
import design from "../assets/svg/des.jpeg";
import support from "../assets/svg/customer-support.jpeg";
import warrenty from "../assets/svg/warrenty.jpeg";
import Price from "../assets/svg/price.jpeg"
import electricdesign from "../assets/svg/electric.jpeg"
import { FaLinkedinIn } from "react-icons/fa";
import { BsFacebook } from "react-icons/bs";
import { FiInstagram } from "react-icons/fi";
import { BsTwitter } from "react-icons/bs";
import {FaPhone } from "react-icons/fa";
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import Prop from "../assets/svg/Prop.jpeg";
import { Link } from 'react-router-dom';

const Interior = () => {
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }, []);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [propertyName, setPropertyName] = useState("");
  const [address, setAddress] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    // handle form submission here
  };
  return (
    <>
       <FloatingWhatsApp accountName='Property10X' phoneNumber='9871424020' avatar={Prop} statusMessage='Replies within a minute' 
                        chatMessage='Welcome to Property10X'
                        allowClickAway={true} />
      <div className='interanimated'>INTERIOR DESIGN🚦</div>
      <div className='intimg'>
        <div className='intform'>
          <form className="intfo" onSubmit={handleSubmit}><h2 className='text-[#005978] font-bold text-2xl'>Meet a deisgner!</h2>
            <div>
            <div>
              <label>
                <input
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Enter your name*"
                  required
                />
              </label>
            </div>
            <div>
              <label>
                <input
                  type="tel"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  pattern="[0-9]{10}"
                  placeholder="Enter your phone number*"
                  required
                />
              </label>
            </div>
            <div>
                <label>
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter your email*"
                    required
                  />
                </label>
              </div>
              <div>
                <label>
                  <input
                    type="text"
                    value={propertyName}
                    onChange={(e) => setPropertyName(e.target.value)}
                    placeholder="Enter property name*"
                    required
                  />
                </label>
              </div>
              <div>
                <label>
                  <input
                    type="text"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    placeholder="Address*"
                    required
                  />
                </label>
              </div>
            <button className="intbutton" type="submit">Get a Call</button>
            </div>
          </form>
        </div>
      </div>
      <div><h2 className='sp'>Home for every style!</h2></div>
      <div class="card-container">
  <div class="card">
  <Link to="/sign-in">
    <img src={design} alt="Card Image"/>
    <div class="card-content">
      <h3 className='text-[#005978] font-semibold'>Personalised design</h3>
      <p>Personalized design is the process of creating products or services that are customized to meet the unique preferences and needs of individual customers.</p>
    </div>
    </Link>
  </div>
  <div class="card">
  <Link to="/sign-in">
    <img src={warrenty} alt="Card Image"/>
    <div class="card-content">
      <h3 className='text-[#005978] font-semibold'>10 year warrenty</h3>
      <p> This serves as a guarantee to the customer that the product they have bought is of good quality and free from defects.</p>
    </div>
    </Link>
  </div>
  <div class="card">
  <Link to="/sign-in">
    <img src={Price} alt="Card Image"/>
    <div class="card-content">
      <h3 className='text-[#005978] font-semibold'>Transparent Pricing</h3>
      <p>It helps to build trust and credibility with customers and can lead to higher customer satisfaction and loyalty.</p>
    </div>
    </Link>
  </div>
  <div class="card">
  <Link to="/sign-in">
    <img src={support} alt="Card Image"/>
    <div class="card-content">
      <h3 className='text-[#005978] font-semibold'>Customer Support</h3>
      <p>Customer Support is a vital aspect of any business that deals with customers and It involves providing assistance.</p>
    </div>
    </Link>
  </div>
</div>
<div class="home-container">
  <div class="overlay">
    <h2 className='homeh2'>Home Design Essentials</h2>
    <p className='homep'>"Creating a Beautiful and Functional Home Design that Fits Your Lifestyle"</p>
    <Link to="/sign-in"> <button className='homebutton'>Book free consultation</button>    </Link>
  </div>
</div>
<div><h2 className='sp'>Let the numbers speak for themselves!</h2></div>
<div className='flex items-center lg:justify-between flex-col lg:flex-row mt-8 lg:mt-4 m-20'>
  <div className='flex flex-col mt-4 lg:mt-0'>
    <span className='text-3xl text-[#005978] font-bold text-center lg:text-left'>500+</span>
    <span className='text-s font-semibold'>Live space homes</span>
  </div>
  
  <div className='hidden lg:block border-l-2 border-[#005978] h-12 my-auto mx-8'></div>
  
  <div className='flex flex-col mt-8 lg:mt-0'>
    <span className='text-3xl text-[#005978] font-bold text-center lg:text-left'>150+</span>
    <span className='text-s font-semibold text-center'>Designers</span>
  </div>
  
  <div className='hidden lg:block border-l-2 border-[#005978] h-12 my-auto mx-8'></div>
  
  <div className='flex flex-col mt-8 lg:mt-0'>
    <span className='text-3xl text-[#005978] font-bold text-center lg:text-left'>50+</span>
    <span className='text-s font-semibold text-center'>Cities</span>
  </div>
</div>
<div className="electriccontainer">
  <div className="electriccontent">
    <h2 className='text-5xl font-bold text-start text-[#005978]'>Have you any plan!</h2>
    <p className='mt-5 text-lg font-semibold'>Design is not just what it looks like and feels like. Design is how it works.</p>
    <Link to="/sign-in"><button className='homebutton mt-8'>Book free consultation</button></Link>
  </div>
  <div className="electricimage">
    <img className='imm' src={electricdesign} alt="Image" ></img>
  </div>
</div>
<div><h2 className='sp'>What we offer!💰</h2>
<Link to="/sign-in"><button className='homebuttonn'>Book free consultation</button></Link>
</div>
<div className=' text-center mt-10 text-[#005978] font-bold text-3xl'>Connect with us</div>
<div className='soc'>
      <div className="social-media">
 <a href=" https://www.linkedin.com/company/property-10x/" target="_blank">
        <i className="FaLinkedinIn"></i>
        <FaLinkedinIn/>
      </a>
      <a href=" https://instagram.com/property__10x?igshid=MWI4MTIyMDE= " target="_blank">
        <i className="FiInstagram"></i>
        <FiInstagram/>
      </a>
      <a href=" https://www.facebook.com/theproperty10x?mibextid=LQQJ4d" target="_blank">
      <i className="BsFacebook"></i>
      <BsFacebook/>
    </a>
    <a href=" # ">
    <i className="BsTwitter"></i>
    <BsTwitter/>
  </a>
    </div>
      </div>
   </>
  );
};
export default Interior;
