/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState } from 'react';
import './Commercial.css';
import Why from  "../components/Why";
import { FaRegHeart, FaHeart, } from 'react-icons/fa';
import { useParams } from 'react-router';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Spinner from '../components/Spinner';
import {doc, getDoc} from 'firebase/firestore';
import {db} from '../firebase'
import './VideoSection.css';
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import Slider from "react-slick"; 
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import Prop from "../assets/svg/Prop.jpeg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShareAlt } from '@fortawesome/free-solid-svg-icons';
import  Modal  from "../components/Modal";
import ShowModal from "../components/ShowModal";
import PropertyForm from './PropertyForm';
const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  prevArrow: <FaChevronLeft className="slick-arrow slick-prev"/>,
  nextArrow: <FaChevronRight className="slick-arrow slick-next" />,
};

const Commericial = () => {
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }, []);
    const [isWishlist, setIsWishlist] = useState(false);
    const handleWishlistClick = () => {
      setIsWishlist(!isWishlist);
    };
    const handleShareClick = (e,id) => {
      e.preventDefault();
      
      // Generate the URL for sharing
      const shareUrl = `/project/${listing.ProjectType}/${listing.Name}/${listing.id}`;
      
      // Check if the navigator.share API is available
      if (navigator.share) {
        navigator.share({
          title: listing.Title,
          text: 'Check out this project!',
          url: shareUrl,
        });
      } else {
        // Fallback for browsers that don't support navigator.share
        const socialMediaUrls = {
          facebook: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}`,
          whatsapp: `https://wa.me/?text=${encodeURIComponent(shareUrl)}`,
          // Add more social media platforms as needed
        };
        
        // Open a new tab/window with the selected social media platform's share URL
        const selectedPlatform = 'whatsapp'; // Change this to the desired platform
        const selectedUrl = socialMediaUrls[selectedPlatform];
        if (selectedUrl) {
          window.open(selectedUrl, '_blank');
        } else {
          // Fallback if the selected platform is not recognized
          window.open(shareUrl, '_blank');
        }
      }
    };
    
//ModalConcept
    const [openModal, setOpenModal] = useState(false);
    const onHandleClick =()=>{
    setOpenModal(true)
    }
    const onHandleClose =() =>{
      setOpenModal(false)
    }
    
    const params = useParams();
    const [listing, setListing] = useState(null);
    const [loading, setLoading] = useState(true);
    // const [shareLinkCopied, setShareLinkCopied] = useState(false); 
    // SwiperCore.use([Autoplay, Navigation, Pagination]);
    useEffect(() => {
        // console.log(params)
      async function fetchListing() {
        const docRef = doc(db, "projects", params.projectId);
        // console.log(`This is the docRef`,docRef);
        const docSnap = await getDoc(docRef);
        // console.log(`This is the docSnap`,docSnap)
        if (docSnap.exists()) {
            // console.log('this if is working perfect')
          setListing(docSnap.data());
          setLoading(false);
        }
      }
      fetchListing();
    }, [params.projectId]);
    if (loading) {
      return <Spinner />;
    }
  
return (
    <>
    <div className='display-copy'>
        <FloatingWhatsApp accountName='Property10X' phoneNumber='9871424020' avatar={Prop} statusMessage='Replies within a second' 
                        chatMessage='Welcome to Property10X'
                        allowClickAway={true} />
    <div className="property-card">
    <div className="property-image">
    <div className="listing-text-boxx">
    <a href="/sign-in" onClick={(e) => { e.stopPropagation(); }}> 
      Download Brochure
    </a>
  </div>
  <Slider {...settings}>
    {listing.imgUrls.map(url => (
      <img key={url} src={url} alt="Property" />
    ))}
  </Slider>
  <button className="wishlist-button" onClick={handleWishlistClick}>
          {isWishlist ? <FaHeart color="red" /> : <FaRegHeart />}
        </button>
        <button className="share-button" onClick={handleShareClick}>
      <FontAwesomeIcon icon={faShareAlt} />
    </button></div>
      <div className="property-details">
        <div className="name"><h2 className='name'>{listing.Name} <span className='text-[#808080] text-xl'>{listing.Location}</span></h2></div>
        <h2 className="property-price">
        {listing.Price}
        </h2>
        <div className="property-info">
          <p>
            Status: <span>{listing.Status}</span>
          </p>
          <p>
            Location: <span>{listing.Location}</span>
          </p>
         
          <p>
            Configuration: <span>{listing.Configurations}</span>
          </p>
          <p>
            Project Size: <span>{listing.ProjectSize}</span>
          </p>
          <p>
            RERA Reg.: <span>{listing.ReraNumber}</span>
          </p>
          <p>
  <a href="https://api.whatsapp.com/send/?phone=919871424020&text&type=phone_number&app_absent=0">
    <button className="button bg-[#1e9148] text-white px-6 py-3">
      <i className="fa fa-whatsapp mr-1" style={{ fontSize: '24px' }}></i> Whatsapp
    </button>
  </a>
</p>

 </div>
        <p className='mt-8 flex flex-col md:flex-row'>
  <Link to="/contactus" className="button ca mb-5 md:mb-0 mr-10 ">
    <i className="mr-2 fas fa-headset"></i> Request a Call Back
  </Link>
 <button onClick={onHandleClick} className="button bg-[#c95128] text-white  px-6 py-3 ">
  <i className="fas fa-download mr-2"></i> Download Brochure
  </button>
</p>
</div>
    </div>
    <Link to="/contactus">
  <div className="container contoffer">
    <div className="offer">
    <span>***Premium Aerocity Available-The Dream Residential Project🏠</span>
    </div>
  </div>
</Link>

    <div><h2 className='sp lg:text-center'>{listing.Name} - Project Info</h2>
        <p className='Projj lg:text-center mt-6 px-4 py-4'>{listing.ProjectInfo}</p></div>
          <div><h2 className='sp lg:text-center'>Why {listing.Name} <span className='text-[#005978]'>?</span></h2></div>
          <div style={{ padding: "0rem", maxWidth: "60rem", margin: "auto", marginTop: "2rem" }}>
  {/* <h2 style={{ textAlign: "center", marginBottom: "2rem", color: "#c95128", fontSize: "3rem" }}>Why This Project</h2> */}
  <ul style={{ listStyle: "none", margin: "0", padding: "0", display: "flex", flexDirection: "column" }}>
    {listing.WhyThisProject.map((element, index) => (
      <li key={index} style={{ marginBottom: "1rem", paddingLeft: "2.5rem", position: "relative", display: "flex" }}>
        <span style={{ position: "absolute", left: "20px", top: "0", transform: "translate(-1rem, 0)", display: "inline-flex", alignItems: "center", justifyContent: "center", backgroundColor: "#005978", color: "#fff", borderRadius: "0.5rem", width: "2rem", height: "2rem", fontSize: "1.5rem" }}>➤</span>
        <span style={{ fontSize: "1rem", paddingLeft: "2.5rem", alignSelf: "center" }}>{element.service}</span>
      </li>
    ))}
  </ul>
</div>

<div className='specify'><h2 className='sp mb-8'>{listing.Name} - Price List<span className='text-black'>.</span></h2></div>
    <div>
  <table>
    <thead>
      <tr>
        <th>Product Type</th>
        <th>Area</th>
        <th>New Home Price</th>
      </tr>
    </thead>
    {listing.PriceList.map((element,index)=>(
    <tbody key={index}>
      <tr>
        <td className='text-[#005978] font-semibold'>{element.UnitType}</td>
        <td>{element.Area}</td>
        <td>{element.NewHomePrice}</td>
      </tr>
    </tbody>
    ))}
  </table>
    </div>
  <PropertyForm/>
    <div><h2 className='sp mb-10'>{listing.Name} - Floor Plan </h2><p className='lg:text-center mt-3 px-4'>{listing.FloorDescription}</p>
    <div className='ml-6 mt-6  lg:ml-[38rem]'><Link to="/contactus" className=" button ca shake animate-bounce">
  <i className=" p-2 fas fa-phone off"></i>Office Space BHK</Link></div>
  </div>
  <div></div>
  <div className='brou'>
  <h2 className='sp'>{listing.Name} - Brochure</h2>
  <iframe id="my-iframe" src={listing.Brochure[0]} type="application/pdf" width="800" height="100%" sandbox></iframe>
</div>
<div><h2 className='sp'>{listing.Name} Video</h2>
  <div className="video-section">
      <video src={listing.ProjectVideo[0]} controls  />
    </div>
</div> 
  <div><h1 className='sp'>Amenities</h1>
    <p className="lg:text-center px-4">Amenities refer to the facilities and services that are provided in a particular setting to enhance the comfort and convenience of the people using them.</p></div>
    <div className='am'>
      {listing.Amenities.map((amenity, index) => (
        <div key={index} className='am'>
          <div className='amenity'>
            <img src={amenity.image} alt={amenity.label} />
            <p>{amenity.label}</p>
          </div> 
        </div>
      ))}
      
    </div>
    {listing.NearByInfra.length > 0 &&
  <div>
    <h1 className='sp'>Nearby Infra</h1>
    <p className="lg:text-center px-4">Living near good infrastructure can save you time and money by reducing your commute time, making it easier to access essential services like healthcare and education, and increasing the overall convenience and quality of life in your community.</p>
    <div className='am'>
      {listing.NearByInfra.map((amenity, index) => (
        <div key={index} className='am'>
          <div className='amenity'>
            <img src={amenity.image} alt={amenity.label} />
            <p>{amenity.label}</p>
            <p>{amenity.Description}</p>
          </div>
        </div>
      ))}
    </div>
  </div>
}
    <div><h2 className='sp'>{listing.Name} - Location Map & Landmarks</h2>
      <div className="map-container">
      <iframe
        title="Google Maps"
        src={`https://maps.google.com/maps?q=${listing.Location}&t=&z=13&ie=UTF8&iwloc=&output=embed`}
        allowFullScreen/>
    </div>
    </div>
    <div><h2 className='sp'>{listing.Name} Project</h2></div>
    <div className="bhutani-projects-container">
      <div className="bhutani-logo-container">
        <img src={listing.BuilderLogo} alt="Bhutani Group logo" className="bhutani-logo" />
      </div>
      <div className="bhutani-projects-info">
        <div className="bhutani-data">
          <h2>Total Projects : <span className='expe'>{listing.BuilderTotalProjects}</span></h2>
     <p>{listing.BuilderDescription}</p>
     <h2>Experience : <span className='expe'>{listing.BuilderExperience}</span></h2>
        </div>
      </div>
    </div>
     <Why/>  
     {openModal && <Modal> <ShowModal onHandleClose={onHandleClose}/>  </Modal>}
     </div>
     </>
  );
};
export default Commericial;