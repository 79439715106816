import { useState, useEffect } from "react";
import { getAuth, signInWithPhoneNumber, updateProfile } from "firebase/auth";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import "./SignIn.css";
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function ShowModal({ onHandleClose }) {
  const [isVerified, setIsVerified] = useState(false);
  const [phone, setPhone] = useState('');
  const [fullName, setFullName] = useState('');
  const [confirmationResult, setConfirmationResult] = useState(null);
  const [isOTPSent, setIsOTPSent] = useState(false);
  const [otpDigits, setOtpDigits] = useState(['', '', '', '', '', '']);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'phone') {
      setPhone(value);  
    } else if (name === 'fullName') {
      setFullName(value);
    }
  };

  const configureCaptcha = () => {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('sign-in-button', {
      'size': 'invisible',
      'callback': (response) => {
        onSignInSubmit();
        console.log("Recaptcha verified");
      },
      defaultCountry: "IN"
    });
  };

  const onSignInSubmit = (e) => {
    e.preventDefault();
    configureCaptcha();
    const phoneNumber = "+91" + phone;
    console.log(phoneNumber);
    const appVerifier = window.recaptchaVerifier;
    const auth = getAuth();
    signInWithPhoneNumber(auth, phoneNumber, appVerifier)
      .then((confirmationResult) => {
        setConfirmationResult(confirmationResult);
        setIsOTPSent(true); // Set OTP sent status to true
        console.log("OTP has been sent");
      }).catch((error) => {
        console.log("SMS not sent");
        alert("Error sending SMS. Please try again later.");
      });
  };

  const handleOtpChange = (index, value) => {
    setOtpDigits((prevState) => {
      const updatedDigits = [...prevState];
      updatedDigits[index] = value;
      return updatedDigits;
    });
  };

  const handleOtpKeyDown = (index, e) => {
    if (e.key === 'Backspace' && otpDigits[index] === '') {
      // Move focus to the previous input box when backspace is pressed on an empty input box
      const previousIndex = index - 1;
      if (previousIndex >= 0) {
        document.getElementById(`otp-${previousIndex}`).focus();
      }
    }
  };

  const handleOtpKeyUp = (index, e) => {
    const currentValue = e.target.value;
    if (currentValue !== '') {
      // Move focus to the next input box when a digit is entered
      const nextIndex = index + 1;
      if (nextIndex < otpDigits.length) {
        document.getElementById(`otp-${nextIndex}`).focus();
      }
    }
  };

  const onSubmitOTP = (e) => {
    e.preventDefault();
    const code = otpDigits.join('');
    console.log(code);
    confirmationResult.confirm(code)
      .then((result) => {
        const user = result.user;
        console.log(JSON.stringify(user));
        const auth = getAuth();
        updateProfile(auth.currentUser, {
          displayName: fullName,
          phoneNumber: "+91" + phone
        }).then(() => {
          console.log("Profile updated successfully");
        }).catch((error) => {
          console.log("Error updating profile");
        });
        setIsVerified(true); // set verification status to true
      }).catch((error) => {
        console.log(error);
        alert("Invalid OTP code. Please try again.");
      });
  };

  useEffect(() => {
    if (isVerified) {
      onHandleClose();
    }
  }, [isVerified, onHandleClose]);

  return (
    <div className="modal-container">
      <div className="form-containerr animated fadeIn">
        <div className="mb-5">
          <h1 className=' text-3xl'>Login<p className='text-[#285768]'>To access complete property details and financial models, please log in with your phone number.</p></h1>
          <button onClick={onHandleClose} className="close-icon">
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
        <form onSubmit={onSignInSubmit}>
          <div id="sign-in-button"></div>
          <input type="text" name="fullName" placeholder="Full name" value={fullName} onChange={handleChange} required />
          <input type="number" name="phone" placeholder="Mobile number" value={phone} onChange={handleChange} required />
          {isOTPSent ? (
            <p className="text-[#005978]">OTP has been sent.</p>
          ) : (
            <button type="submitt">Get OTP</button>
          )}
        </form>
        {isOTPSent && !isVerified && (
          <form onSubmit={onSubmitOTP}>
            <div className="otp-input-container">
              {otpDigits.map((digit, index) => (
                <input
                  key={index}
                  id={`otp-${index}`}
                  type="number"
                  name={`otp-${index}`}
                  placeholder="•"
                  value={digit}
                  onChange={(e) => handleOtpChange(index, e.target.value)}
                  onKeyDown={(e) => handleOtpKeyDown(index, e)}
                  onKeyUp={(e) => handleOtpKeyUp(index, e)}
                  maxLength={1}
                  required
                />
              ))}
            </div>
            <button type="submitt">Submit</button>
          </form>
        )}
        {isVerified && (
          <p className="text-[#005978]">Thank you for verifying your phone number.</p>
        )}
      </div>
    </div>
  );
}