/* eslint-disable no-unused-vars */
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import firebase from "firebase/compat/app";
import "firebase/auth";
const firebaseConfig = {
  apiKey: "AIzaSyCGAExQE3mFIPKKo2Q6CM61Os1S6KlDoSM",
  authDomain: "property10x-1.firebaseapp.com",
  projectId: "property10x-1",
  storageBucket: "property10x-1.appspot.com",
  messagingSenderId: "98752474545",
  appId: "1:98752474545:web:4c1d14659003c11eeba190",
  measurementId: "G-8Y25D7LZP5",
};
firebase.initializeApp(firebaseConfig);
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const db = getFirestore();
