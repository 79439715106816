import React from 'react'
import build from "../assets/svg/bui-removebg.png"
import "./Buildimg.css";
const BuildImg = () => {
  return (
    <div>
    <img src={build} alt="build" className="buildimg"/>
    </div>
  )
}
export default BuildImg;