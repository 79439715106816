/* eslint-disable no-unused-vars */
import { useState } from "react";
import Spinner from "../../components/Spinner";
import { toast } from "react-toastify";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import './createlistingstyle.css'
import { getAuth } from "firebase/auth";
import { v4 as uuidv4 } from "uuid";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { db } from "../../firebase";
import { useNavigate } from "react-router-dom";
import './CreateProjectStyle.css'
export default function CreateListing() {
  //--------------------------------------------------------The amenities section------------------------------------------------
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([
    { id: 1, label: "CCTV / Video Surveillance", checked: false, image: 'https://firebasestorage.googleapis.com/v0/b/property10x-cc854.appspot.com/o/cctv-camera.png?alt=media&token=5c439a44-93f8-41e4-9326-f86e4eb2a7f9'},
    { id: 2, label: "Elevator", checked: false, image: 'https://firebasestorage.googleapis.com/v0/b/property10x-cc854.appspot.com/o/elevator.png?alt=media&token=28220752-f8ec-4e0b-bc70-bbf047985f35'},
    { id: 3, label: "Fire extunguisher", checked: false, image: 'https://firebasestorage.googleapis.com/v0/b/property10x-cc854.appspot.com/o/fire-extinguisher%20(1).png?alt=media&token=aa6fac17-14d7-434d-b317-18a174f7ef26' },
    { id: 4, label: "Gym", checked: false, image: 'https://firebasestorage.googleapis.com/v0/b/property10x-cc854.appspot.com/o/gym.png?alt=media&token=84c4c0a5-ef44-478e-bac7-a1f04edb6ce7' },
    { id: 5, label: "Hospital", checked: false, image: 'https://firebasestorage.googleapis.com/v0/b/property10x-cc854.appspot.com/o/hospital.png?alt=media&token=e8a1891c-d3ec-4edd-a674-14acc065bfeb' },
    { id: 6, label: "Hydro Power", checked: false, image: 'https://firebasestorage.googleapis.com/v0/b/property10x-cc854.appspot.com/o/hydro-power.png?alt=media&token=165b6db6-c20d-4390-989c-c95be8422cde' },
    { id: 7, label: "Intercom", checked: false, image: 'https://firebasestorage.googleapis.com/v0/b/property10x-cc854.appspot.com/o/intercom.png?alt=media&token=8176d083-b2b9-429a-b117-b83c74c75d22'},
    { id: 8, label: "Internet", checked: false, image: 'https://firebasestorage.googleapis.com/v0/b/property10x-cc854.appspot.com/o/internet.png?alt=media&token=70d9a4c5-47fd-41b0-8427-933fb48cb97e' },
    { id: 9, label: "Night Club", checked: false, image: 'https://firebasestorage.googleapis.com/v0/b/property10x-cc854.appspot.com/o/night-club.png?alt=media&token=aaf1d483-563e-45d0-a3aa-fc5e8bb6c351'},
    { id: 10, label: "Park", checked: false, image: 'https://firebasestorage.googleapis.com/v0/b/property10x-cc854.appspot.com/o/park.png?alt=media&token=ab2b3291-aaf2-48fe-8cc5-50dc9e9b0ca1' },
    { id: 11, label: "Parking Car", checked: false, image: 'https://firebasestorage.googleapis.com/v0/b/property10x-cc854.appspot.com/o/parking-car.png?alt=media&token=47c063de-9276-4578-a5f0-1ee8e26917aa' },
    { id: 12, label: "Pipe", checked: false, image: 'https://firebasestorage.googleapis.com/v0/b/property10x-cc854.appspot.com/o/pipe.png?alt=media&token=1dba1b44-a774-4896-ba3a-a38e87014f63' },
    { id: 13, label: "Playground", checked: false, image: 'https://firebasestorage.googleapis.com/v0/b/property10x-cc854.appspot.com/o/playground.png?alt=media&token=eb653ce2-184a-4173-95b6-73ed83ae7482' },
    { id: 14, label: "School", checked: false, image: 'https://firebasestorage.googleapis.com/v0/b/property10x-cc854.appspot.com/o/school.png?alt=media&token=c2304436-a2ab-400a-8166-9a4f9b97ad33' },
    { id: 15, label: "Shopping Mall", checked: false, image: 'https://firebasestorage.googleapis.com/v0/b/property10x-cc854.appspot.com/o/shopping-mall.png?alt=media&token=7675a93c-3baa-43f5-a5a3-bec8655fa3a8' },
    { id: 16, label: "Sports", checked: false, image: 'https://firebasestorage.googleapis.com/v0/b/property10x-cc854.appspot.com/o/sports.png?alt=media&token=ea5f2344-7d98-49a5-92a5-3276ea167bf9' },
    { id: 17, label: "Store", checked: false, image: 'https://firebasestorage.googleapis.com/v0/b/property10x-cc854.appspot.com/o/store.png?alt=media&token=11f72b42-6641-4518-b171-c993ee45b249' },
    { id: 18, label: "Swimming Pool", checked: false, image: 'https://firebasestorage.googleapis.com/v0/b/property10x-cc854.appspot.com/o/swimmer.png?alt=media&token=8c4544b7-6928-4d11-8231-5cfe3998840d' },
    { id: 19, label: "Railway Station", checked: false, image: 'https://firebasestorage.googleapis.com/v0/b/property10x-cc854.appspot.com/o/train-station.png?alt=media&token=8cc4759e-4284-484c-a376-0e1035ff9514' },
    { id: 20, label: "Table Tennis", checked: false, image: 'https://firebasestorage.googleapis.com/v0/b/property10x-cc854.appspot.com/o/table-tennis.png?alt=media&token=12b66934-5d6f-4713-aa19-125400ff7d86'},
    { id: 21, label: "Yoga", checked: false, image: 'https://firebasestorage.googleapis.com/v0/b/property10x-cc854.appspot.com/o/lotus.png?alt=media&token=f7e591eb-96de-4ff1-9708-dc7582728a61'},
    { id: 22, label: "Cycling", checked: false, image: 'https://firebasestorage.googleapis.com/v0/b/property10x-cc854.appspot.com/o/cycling.png?alt=media&token=360d07bb-4692-46ff-b322-3da85c471496' },
    { id: 23, label: "Tennis", checked: false, image: 'https://firebasestorage.googleapis.com/v0/b/property10x-cc854.appspot.com/o/tennis.png?alt=media&token=e8880dbb-c6a9-4f34-ab3e-d9518f486084' },
    { id: 24, label: "Billiard", checked: false, image: 'https://firebasestorage.googleapis.com/v0/b/property10x-cc854.appspot.com/o/billiard-ball.png?alt=media&token=6c243bbf-d940-4d00-9863-6721979a5904' },
    { id: 25, label: "Club House", checked: false, image: 'https://firebasestorage.googleapis.com/v0/b/property10x-cc854.appspot.com/o/club.png?alt=media&token=cbd23859-bb67-4dbc-9c27-477934f34e6a' },
    { id: 26, label: "Park", checked: false, image: 'https://firebasestorage.googleapis.com/v0/b/property10x-cc854.appspot.com/o/store.png?alt=media&token=11f72b42-6641-4518-b171-c993ee45b249'},
    { id: 27, label: "24*7 Security", checked: false, image: 'https://firebasestorage.googleapis.com/v0/b/property10x-cc854.appspot.com/o/policeman.png?alt=media&token=8ab1229c-a902-4351-806f-ae4c8c15498d' },
    { id: 28, label: "Sewage", checked: false, image: 'https://firebasestorage.googleapis.com/v0/b/property10x-cc854.appspot.com/o/sewage.png?alt=media&token=8a0e0972-d719-488c-b133-46810d940457'},
    { id: 29, label: "Shopping", checked: false, image: 'https://firebasestorage.googleapis.com/v0/b/property10x-cc854.appspot.com/o/shopping-cart.png?alt=media&token=761da446-5678-4743-9753-75f928e25d12' },
    { id: 30, label: "Swimming Pool", checked: false, image: 'https://firebasestorage.googleapis.com/v0/b/property10x-cc854.appspot.com/o/swimming-pool.png?alt=media&token=882a79a3-bdf2-4c74-9d62-553653af3ea2'},
    { id: 31, label: "Burglar Alarm", checked: false, image: 'https://firebasestorage.googleapis.com/v0/b/property10x-cc854.appspot.com/o/BurgularAlarm.png?alt=media&token=69fe1e69-8afc-4f7b-8113-d1d8df78a795'},
    { id: 32, label: "Emergency Rescue Alarm", checked: false, image: 'https://firebasestorage.googleapis.com/v0/b/property10x-cc854.appspot.com/o/Emergency%20Rescue%20Alarms.png?alt=media&token=9a347eaf-e67f-461d-a47a-cd6ab807409c'},
    { id: 33, label: "Entertainment Zone", checked: false, image: 'https://firebasestorage.googleapis.com/v0/b/property10x-cc854.appspot.com/o/EntertainmentZone.png?alt=media&token=45edcc59-9cc6-4177-8149-85717121b8d6'},
    { id: 34, label: "Power Backup", checked: false, image: 'https://firebasestorage.googleapis.com/v0/b/property10x-cc854.appspot.com/o/Powert%20Backup.png?alt=media&token=d054b873-5f8d-4224-963a-16aca42091ea'},
    { id: 35, label: "MultiBrand Retail", checked: false, image: 'https://firebasestorage.googleapis.com/v0/b/property10x-cc854.appspot.com/o/MultibrandRetail.png?alt=media&token=37b12408-5b25-437d-bd0e-e9f8decbdb37'},
    { id: 36, label: "Kids Play Area", checked: false, image: 'https://firebasestorage.googleapis.com/v0/b/property10x-cc854.appspot.com/o/Kids%20Play%20Area.png?alt=media&token=9da13bcf-0ccb-4b95-923e-c1c996992b2e'},
    { id: 37, label: "High Speed Escalator", checked: false, image: 'https://firebasestorage.googleapis.com/v0/b/property10x-cc854.appspot.com/o/escalator.png?alt=media&token=2218167d-0226-4e46-af59-4f2645048f77'},
    { id: 38, label: "High Speed Elevator", checked: false, image: 'https://firebasestorage.googleapis.com/v0/b/property10x-cc854.appspot.com/o/elevator.png?alt=media&token=91684338-636e-4393-9418-f7012d0d3a54'},
    { id: 39, label: "ATM", checked: false, image: 'https://firebasestorage.googleapis.com/v0/b/property10x-cc854.appspot.com/o/atm-machine.png?alt=media&token=b6e6f3bd-b083-4808-b367-0ef51018ce0f'},
    { id: 40, label: "Smoke Heat Sensor", checked: false, image: 'https://firebasestorage.googleapis.com/v0/b/property10x-cc854.appspot.com/o/Smoke%20Heat%20sensor.png?alt=media&token=f0cf90d1-fa8c-449a-901c-cd17a859e9e6'},
    { id: 41, label: "Rainwater Harvesting", checked: false, image: 'https://firebasestorage.googleapis.com/v0/b/property10x-cc854.appspot.com/o/RainwaterHarvesting.png?alt=media&token=99eb7415-4ec4-495f-9b67-5f02c9ffb763'},
    { id: 42, label: "24*7 Water Supply", checked: false, image: 'https://firebasestorage.googleapis.com/v0/b/property10x-cc854.appspot.com/o/water-tap.png?alt=media&token=70b03971-8da7-435f-b0b5-4aaaf6e5e13a'},
    { id: 43, label: "Party Hall", checked: false, image: 'https://firebasestorage.googleapis.com/v0/b/property10x-cc854.appspot.com/o/dance.png?alt=media&token=2db14062-d09b-4f85-a0d3-85cbaa2eeb63'},
    { id: 44, label: "Spa", checked: false, image: 'https://firebasestorage.googleapis.com/v0/b/property10x-cc854.appspot.com/o/massage.png?alt=media&token=8202c67f-7916-4e61-8ff9-b3a5142e10d7'},
    { id: 45, label: "Indoor Games", checked: false, image: 'https://firebasestorage.googleapis.com/v0/b/property10x-cc854.appspot.com/o/bowls.png?alt=media&token=bad3de77-d122-4fda-a3c0-6267d6c446c8'},
    { id: 46, label: "Food Court", checked: false, image: 'https://firebasestorage.googleapis.com/v0/b/property10x-cc854.appspot.com/o/food-court.png?alt=media&token=58dbeb4a-d1df-4a3f-a5fb-c9613e53f9b5'},
    { id: 47, label: "Restaurant", checked: false, image: 'https://firebasestorage.googleapis.com/v0/b/property10x-cc854.appspot.com/o/restaurant.png?alt=media&token=d6668026-b3a2-4e25-8512-7c8d615d5c41'},
    { id: 48, label: "Large Green Area", checked: false, image: 'https://firebasestorage.googleapis.com/v0/b/property10x-cc854.appspot.com/o/park.png?alt=media&token=72164cfb-127a-4552-94c4-376c5bde0224'},
    { id: 49, label: "AC Waiting Lobby", checked: false, image: 'https://firebasestorage.googleapis.com/v0/b/property10x-cc854.appspot.com/o/area.png?alt=media&token=c76c3167-fff5-4548-89c8-553c5b284395'},
    // { id: 50, label: "Smoke Heat Sensor", checked: false, image: ''},
    // { id: 46, label: "Smoke Heat Sensor", checked: false, image: ''},
    // { id: 46, label: "Smoke Heat Sensor", checked: false, image: ''},
  ]);
  const handleCheckboxChange = (event) => {
    const checkboxId = Number(event.target.id);
    setSelectedCheckboxes((prevState) =>
      prevState.map((checkbox) =>
        checkbox.id === checkboxId
          ? { ...checkbox, checked: !checkbox.checked } // toggle the checked property
          : checkbox
      )
    );
  };
  const [NearByInfra, setNearbyInfra] = useState([
    {id: 101, label: "Hospital", checked: false, description: '', image: 'https://firebasestorage.googleapis.com/v0/b/property10x-cc854.appspot.com/o/hospital.png?alt=media&token=dd718b77-c2bb-4b95-9ab8-39e07e5a152a'},
    {id: 102, label: "School", checked: false, description: '', image: 'https://firebasestorage.googleapis.com/v0/b/property10x-cc854.appspot.com/o/school.png?alt=media&token=3f597fa8-8103-47e5-b044-508fd326b7a2'},
    {id: 103, label: 'Metro', checked: false, description: '', image: 'https://firebasestorage.googleapis.com/v0/b/property10x-cc854.appspot.com/o/train.png?alt=media&token=7a563fa9-8924-4341-a663-13cc407bb754'},
    {id: 104, label: "Airport", checked: false, description: '', image: 'https://firebasestorage.googleapis.com/v0/b/property10x-cc854.appspot.com/o/flights.png?alt=media&token=0baad1a1-c7e1-4d56-bca1-7933b6502748'},
    {id: 105, label: 'Retail Shops', checked: false, description: '', image: 'https://firebasestorage.googleapis.com/v0/b/property10x-cc854.appspot.com/o/store.png?alt=media&token=721fd4f5-5c01-4355-a6ae-a31c731bbbbc'},
    {id: 106, label: "Mall", checked: false, description: '', image: 'https://firebasestorage.googleapis.com/v0/b/property10x-cc854.appspot.com/o/shopping-center.png?alt=media&token=a1b19442-0f6c-476b-b023-2385ad20f97d'},
  ]);
  const handleCheckboxChange2 = (event) => {
    const checkboxId = Number(event.target.id);
    setNearbyInfra((prevState) =>
      prevState.map((checkbox) =>
        checkbox.id === checkboxId
          ? { ...checkbox, checked: !checkbox.checked } // toggle the checked property
          : checkbox
      )
    );
  };
  const handleDescriptionChange = (id, description) => {
    setNearbyInfra(prevState =>
      prevState.map(item => {
        if (item.id === id) {
          return { ...item, description };
        }
        return item;
      })
    );
  };
  const [serviceList, setServiceList] = useState([
    {id: 201, UnitType: "", Area: "", NewHomePrice: "", checked: false}, 
    {id: 202, UnitType: "", Area: "", NewHomePrice: "", checked: false},
    {id: 203, UnitType: "", Area: "", NewHomePrice: "", checked: false}, 
    {id: 204, UnitType: "", Area: "", NewHomePrice: "", checked: false},
    {id: 205, UnitType: "", Area: "", NewHomePrice: "", checked: false}, 
    {id: 206, UnitType: "", Area: "", NewHomePrice: "", checked: false},
    {id: 207, UnitType: "", Area: "", NewHomePrice: "", checked: false}, 
    {id: 208, UnitType: "", Area: "", NewHomePrice: "", checked: false},
    {id: 209, UnitType: "", Area: "", NewHomePrice: "", checked: false}, 
    {id: 210, UnitType: "", Area: "", NewHomePrice: "", checked: false},
    {id: 211, UnitType: "", Area: "", NewHomePrice: "", checked: false},
    {id: 212, UnitType: "", Area: "", NewHomePrice: "", checked: false},
    {id: 213, UnitType: "", Area: "", NewHomePrice: "", checked: false},
    {id: 214, UnitType: "", Area: "", NewHomePrice: "", checked: false},
    {id: 215, UnitType: "", Area: "", NewHomePrice: "", checked: false},
    {id: 216, UnitType: "", Area: "", NewHomePrice: "", checked: false},
    {id: 217, UnitType: "", Area: "", NewHomePrice: "", checked: false},
    {id: 218, UnitType: "", Area: "", NewHomePrice: "", checked: false},
    {id: 219, UnitType: "", Area: "", NewHomePrice: "", checked: false},
    {id: 220, UnitType: "", Area: "", NewHomePrice: "", checked: false},
  ]);
  
  const handleCheckboxChange3 = (event) => {
    const checkboxId = Number(event.target.id);
    setServiceList((prevState) =>
      prevState.map((checkbox) =>
        checkbox.id === checkboxId
          ? { ...checkbox, checked: !checkbox.checked } // toggle the checked property
          : checkbox
      )
    );
  };
  
  const handleDescriptionChange3 = (id, field, value) => {
    setServiceList(prevState =>
      prevState.map(item => {
        if (item.id === id) {
          return { ...item, [field]: value };
        }
        return item;
      })
    );
  };
  
        const [specsList, setSpecsList] = useState([{ service: "" }]);

        const handleSpecsChange = (event, index) => {
          const { name, value } = event.target;
          const list = [...specsList];
          list[index][name] = value;
          setSpecsList(list);
        };
      
        const handleSpecsAdd = () => {
          setSpecsList([...specsList, { service: "" }]);
        };
      
        const handleSpecsRemove = (index) => {
          const list = [...specsList];
          list.splice(index, 1);
          setSpecsList(list);
        };
  const navigate = useNavigate();
  const auth = getAuth();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    Name: "",
    ProjectType: "",
    Location: "",
    Price: "",
    PricePerUnit: "",
    Status: "",
    ProjectSize: "",
    Configurations: "",
    ReraNumber: "",
    ProjectInfo: "",
    FloorPlanImage: "",
    FloorDescription: "",
    Structure: "",
    BuilderName: "",
    BuilderLogo: "",
    BuilderTotalProjects: "",
    BuilderExperience: "",
    Specifications: "",
    BuilderDescription: "",
    Bed: "",
    Bath: "",
    Balcony: "",
    Furnishing: "",
    Floor: "",
    Facing: "",
    CarPark: "",
    images: {},
  });
  const {
    Name, 
    ProjectType,
    Location,
    Price,
    PricePerUnit,
    Status,
    ProjectSize,
    Configurations,
    ReraNumber,
    ProjectInfo,
    FloorPlanImage,
    FloorDescription,
    Structure,
    BuilderName,
    BuilderLogo,
    BuilderTotalProjects,
    BuilderExperience,
    BuilderDescription,
    Specifications,
    Bed,
    Bath,
    Balcony,
    Furnishing,
    Floor,
    Facing,
    CarPark,
    images,
  
  } = formData;
  function onChange(e) {
    let boolean = null;
    if (e.target.value === "true") {
      boolean = true;
    }
    if (e.target.value === "false") {
      boolean = false;
    }
    // Files
    if (e.target.files) {
      if (e.target.id === "images") {
        setFormData((prevState) => ({
          ...prevState,
          images: e.target.files,
        }));
      } else if (e.target.id === "FloorPlanImage") {
        setFormData((prevState) => ({
          ...prevState,
          FloorPlanImage: e.target.files,
        }));
      } else if (e.target.id === "BuilderLogo") {
        setFormData((prevState) => ({
          ...prevState,
          BuilderLogo: e.target.files,
        }));
      }
      else if (e.target.id === "brochure"){
        setBrochure(e.target.files);
      }
      else if (e.target.id === "projectVideo"){
        setProjectVideo(e.target.files);
      }
    }
    // Text/Boolean/Number
    if (!e.target.files) {
      setFormData((prevState) => ({
        ...prevState,
        [e.target.id]: boolean ?? e.target.value,
      }));
    }
  }
  const [brochure, setBrochure] = useState('');
  const [projectVideo, setProjectVideo] = useState('');
  const [projectType, setProjectType] = useState('');
  const [first, setFirst] = useState('');

  async function onSubmit(e) {
    e.preventDefault();
    setLoading(true);
    if (images.length > 30) {
      setLoading(false);
      toast.error("maximum 30 images are allowed");
      return;
    }
    
    // const bucket = admin.storage().bucket();
    async function storePDF(pdf) {
      return new Promise((resolve, reject) => {
        const storage = getStorage();
        const filename = `${pdf.originalname}-${Date.now()}-${uuidv4()}`;
        const storageRef = ref(storage, filename);
        const uploadTask = uploadBytesResumable(storageRef, pdf);
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress =
              (snapshot.bytesTransferred/snapshot.totalBytes)*100;
              switch(snapshot.state) {
                case "paused":
                  break;
                case "running":
                  break;
                default:
              }
          },
          (error) => {
            reject(error);
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              resolve(downloadURL);
            });
          }
        )
      });
    }
    
    async function storeImage(image) {
      return new Promise((resolve, reject) => {
        const storage = getStorage();
        const filename = `${auth.currentUser.uid}-${image.name}-${uuidv4()}`;
        const storageRef = ref(storage, filename);
        const uploadTask = uploadBytesResumable(storageRef, image);
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            // Observe state change events such as progress, pause, and resume
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

            switch (snapshot.state) {
              case "paused":
                break;
              case "running":          
                break;
              default:
            }
          },
          (error) => {
            reject(error);
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              resolve(downloadURL);
            });
          }
        );
      });
    }


    const imgUrls = await Promise.all(
      [...images].map((image) => storeImage(image))
    ).catch((error) => {
      setLoading(false);
      toast.error("Images not uploaded");
      return;
    });
    
    const floorPlanImgUrls = await Promise.all(
      [...FloorPlanImage].map((image) => storeImage(image))
    ).catch((error) => {
      setLoading(false);
      toast.error("FloorPlan images not uploaded");
      return;
    });
    
    const builderLogoUrls = await Promise.all(
      [...BuilderLogo].map((image) => storeImage(image))
    ).catch((error) => {
      setLoading(false);
      toast.error("Builder logo not uploaded");
      return;
    });

    const brochureUrls = await Promise.all(
      [...brochure].map((pdfs)=>storePDF(pdfs))
    ).catch((error)=> {
      setLoading(false);
      toast.error("The Brochure Not Uploaded");
      return;
    })
    const projectVideoUrls = await Promise.all(
      [...projectVideo].map((video)=>storeImage(video))
    ).catch((error)=> {
      setLoading(false);
      toast.error("The Video not uploaded");
      return;
    })
    
    const updatedCheckBox = selectedCheckboxes.filter(item => item.checked===true) //The Amenity Section
    const updatedCheckBox2 = NearByInfra.filter(item => item.checked===true)       //The Nearby Infra section
    const updatedCheckBox3 = serviceList.filter(item => item.checked===true)      //The Price List section

    const formDataCopy = {
      ...formData,
      imgUrls,
      Amenities: updatedCheckBox,
      NearByInfra: updatedCheckBox2,
      PriceList: updatedCheckBox3,
      images: images,
      WhyThisProject: specsList,
      FloorPlanImage: floorPlanImgUrls,
      BuilderLogo: builderLogoUrls,
      Brochure: brochureUrls,
      ProjectVideo: projectVideoUrls,
      timestamp: serverTimestamp(),
      userRef: auth.currentUser.uid,
    };
    delete formDataCopy.images;
    !formDataCopy.offer && delete formDataCopy.discountedPrice;
    const docRef = await addDoc(collection(db, "ResidentialProjects"), formDataCopy);
    setLoading(false);
    toast.success("Listing created");
    navigate(`/residentialproject/${formDataCopy.Type}/${docRef.id}`);
  }
  if (loading) {  
    return <Spinner />
  }
  return (
    <main className="bg-blue-100">
    <h1 className="text-[#005978] text-3xl text-center mt-6 font-bold">Create a Listing for Residential Project</h1>
    <form className='p-4 rounded' style={{  border: '1px solid black' }} onSubmit={onSubmit}>
    <div className="mb-6">
        <p className="text-lg font-semibold">Project Images-Add multiple Images</p>
        <p className="text-gray-600">
          The first image will be the cover
        </p>
        <input
          type="file"
          id="images"
          name="Images"
          onChange={onChange}
          accept=".jpg,.png,.jpeg, .gif, .bmp, .raw, .svg, .webp"
          multiple
          required
          className="w-full px-3 py-1.5 text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:bg-white focus:border-slate-600"
        />

      </div>
      <p className="lg mt-6 font-semibold">Name of the project</p>
      <input
        type="text"
        id="Name"
        value={Name}
        onChange={onChange}
        placeholder="The Name of the Project"
        maxLength="32"
        required
        className="w-full md:w-1/4 px-4 py-2 text-xl text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mb-6"
      />
          <p className="lg mt-6 font-semibold">Project Location</p>
      <input
        type="text"
        id="Location"
        value={Location}
        onChange={onChange}
        placeholder="Enter the location of the project"
        maxLength="32"
        required
        className="w-full md:w-1/4 px-4 py-2 text-xl text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mb-6"
      />
      <p className="lg mt-6 font-semibold">Type of Project</p>
      <input
        type="text"
        id="ProjectType"
        value={ProjectType}
        onChange={onChange}
        placeholder="The Type of the Project"
        maxLength="32"
        required
        className="w-full md:w-1/4 px-4 py-2 text-xl text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mb-6"
      />
      <p className="lg mt-6 font-semibold">Price of Project</p>
      <input
        type="text"
        id="Price"
        value={Price}
        onChange={onChange}
        placeholder="Enter the price the project is starting from"
        maxLength="32"
        required
        className="w-full md:w-1/4 px-4 py-2 text-xl text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mb-6"
      />
      {/* <p className="lg mt-6 font-semibold">Price Per Unit</p>
      <input
        type="text"
        id="PricePerUnit"
        value={PricePerUnit}
        onChange={onChange}
        placeholder="Enter the price the project is starting from"
        maxLength="32"
        // required
        className="w-full md:w-1/4 px-4 py-2 text-xl text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mb-6"
      /> */}
       <p className="lg mt-6 font-semibold">Status</p>
      <input
        type="text"
        id="Status"
        onChange={onChange}
        value={Status}
        placeholder="eg: Newly Launched "
        maxLength="500"
        required
        className="w-full md:w-1/4 px-4 py-2 text-xl text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mb-6"
      />
         <p className="lg mt-6 font-semibold">Configuration of the Project</p>
      <input
        type="Text"
        id="Configurations"
        onChange={onChange}
        value={Configurations}
        placeholder="Configurations of the Project"
        maxLength="32000000"
        // required
        className="w-full md:w-1/4 px-4 py-2 text-xl text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mb-6"
      />
      <p className="lg mt-6 font-semibold">Project Size</p>
      <input
        type="text"
        id="ProjectSize"
        onChange={onChange}
        value={ProjectSize}
        placeholder="Enter the Size of the Project"
        maxLength="500"
        className="w-full md:w-1/4 px-4 py-2 text-xl text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mb-6"
      />
      <p className="lg mt-6 font-semibold">RERA Number</p>
      <input
        type="Text"
        id="ReraNumber"
        onChange={onChange}
        value={ReraNumber}
        placeholder="Enter the RERA Number"
        maxLength="3200"
        // required
        className="w-full md:w-1/4 px-4 py-2 text-xl text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mb-6"
      />
       {/* <p className="lg mt-6 font-semibold">Specification</p>
      <input
        type="Text"
        id="Specifications"
        onChange={onChange}
        value={Specifications}
        placeholder="Enter the Specification"
        maxLength="3200"
        // required
        className="w-full md:w-1/4 px-4 py-2 text-xl text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mb-6"
      /> */}
       <p className="lg mt-6 font-semibold">Project Info / About the Project</p>
      <input
        type="Description"
        id="ProjectInfo"
        onChange={onChange}
        value={ProjectInfo}
        placeholder="Enter the Project Information"
        maxLength="32000000"
        // required
        className="w-full md:w-1/4 px-4 py-2 text-xl text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mb-6"
      />
    <p className="lg mt-6 font-semibold">Why This Project?</p>
    <form className="w-full md:w-1/2 px-4 py-2 " autoComplete="off" onSubmit={onSubmit}>
  <div className="form-field">
    {/* <label htmlFor="service">Add all the Specifications</label> */}
    {specsList.map((singleService, index) => (
      <div key={index} className="services">
        <div className="first-division">
          <input
            name="service"
            type="text"
            id="service"
            value={singleService.service}
            onChange={(e) => handleSpecsChange(e, index)}
            required
          />
          {specsList.length - 1 === index && specsList.length < 20 && (
            <button
              type="button"
              onClick={handleSpecsAdd}
              className="add-btn"
            >
              <span>Add Points</span>
            </button>
          )}
        </div>
        <div className="second-division">
          {specsList.length !== 1 && (
            <button
              type="button"
              onClick={() => handleSpecsRemove(index)}
              className="remove-btn"
            >
              <span>Remove</span>
            </button>
          )}
        </div>
      </div>
    ))}
  </div>
</form>
<h1>Project-Price List</h1>
<div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
  {serviceList.map((checkbox) => (
    <div key={checkbox.id} style={{ width: "100%", maxWidth: "200px", margin: "8px", border: "1px solid gray", borderRadius: "4px", padding: "8px" }}>
      <label htmlFor={checkbox.id}>
      <input
          type="checkbox"
          id={checkbox.id}
          name={checkbox.label}
          checked={checkbox.checked}
          onChange={handleCheckboxChange3}
        />
      <input
        type="text"
        placeholder="Enter UnitType"
        value={checkbox.UnitType}
        onChange={event =>
          handleDescriptionChange3(checkbox.id, "UnitType", event.target.value)
        }
      />
      <input
        type="text"
        placeholder="Enter Area"
        value={checkbox.Area}
        onChange={event =>
          handleDescriptionChange3(checkbox.id, "Area", event.target.value)
        }
      />
      <input
        type="text"
        placeholder="Enter NewHomePrice"
        value={checkbox.NewHomePrice}
        onChange={event =>
          handleDescriptionChange3(checkbox.id, "NewHomePrice", event.target.value)
        }
      />
      </label>
    </div>
  ))}
    </div>
    <div className="mb-6">
      <p className="text-lg font-semibold">Upload the Project brochure</p>
      <input
        type="file"
        id="brochure"
        name="brochure"
        onChange={onChange}
        accept=".pdf"
        className="w-full px-3 py-1.5 text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:bg-white focus:border-slate-600"
      />
    </div>
    <div className="mb-6">
        <p className="text-lg font-semibold">Upload The Project Video</p>
        <input
          type="file"
          id="projectVideo"
          name="bprojectVideorochure"
          onChange={onChange}
          accept=".mp4"
          // multiple
          // required
          className="w-full px-3 py-1.5 text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:bg-white focus:border-slate-600"
        />
      </div>
      <h1>Amenities</h1>
      <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
        {selectedCheckboxes.map((checkbox) => (
          <div key={checkbox.id} style={{ width: "100%", maxWidth: "200px", margin: "8px", border: "1px solid gray", borderRadius: "4px", padding: "8px" }}>
            <label htmlFor={checkbox.id}>
              <input
                type="checkbox"
                id={checkbox.id}
                name={checkbox.label}
                checked={checkbox.checked}
                onChange={handleCheckboxChange}
              />
              {checkbox.label}
            </label>
            <img src={checkbox.image} alt={checkbox.label} style={{ height: "54px", width: "54px", marginTop: "8px" }} />
          </div>
        ))}
</div>
<h1>NearBy Infra</h1>
{/* This is the Nearby Infra Sections */}
<div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
  {NearByInfra.map((checkbox) => (
    <div key={checkbox.id} style={{ width: "100%", maxWidth: "200px", margin: "8px", border: "1px solid gray", borderRadius: "4px", padding: "8px" }}>
      <label htmlFor={checkbox.id}>
        <input
          type="checkbox"
          id={checkbox.id}
          name={checkbox.label}
          checked={checkbox.checked}
          onChange={handleCheckboxChange2}
        />
        <input
          type="text"
          placeholder="Enter description"
          value={checkbox.description}
          onChange={event =>
            handleDescriptionChange(checkbox.id, event.target.value)
          }
        />
        {checkbox.label}
      </label>
      <img src={checkbox.image} alt={checkbox.label} style={{ height: "54px", width: "54px", marginTop: "8px" }} />
    </div>
  ))}
</div>
{/*  
      <p className="lg mt-6 font-semibold">Floor Description</p>
      <input
        type="Text"
        id="FloorDescription"
        onChange={onChange}
        value={FloorDescription}
        placeholder="Enter the Description about the Floor"
        maxLength="32000000"
        // required
        className="w-full md:w-1/4 px-4 py-2 text-xl text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mb-6"
      /> */}
      {/* <p className="lg mt-6 font-semibold">Enter about the Structure of the Project</p>
      <input
        type="Text"
        id="Structure"
        onChange={onChange}
        value={Structure}
        placeholder="Mention about the Structure of the Project."
        maxLength="32000000"
        // required
        className="w-full md:w-1/4 px-4 py-2 text-xl text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mb-6"
      /> */}
       {/* -------------------------------------This section is about Builder------------------------------------------------- */}
       <p className="lg mt-6 font-semibold">Builder Name</p>
      <input
        type="Text"
        id="BuilderName"
        onChange={onChange}
        value={BuilderName}
        placeholder="Enter the name of the Builder"
        maxLength="32"
        // required
        className="w-full md:w-1/4 px-4 py-2 text-xl text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mb-6"
      />
    <p className="lg mt-6 font-semibold">Enter Total projects of the Builder</p>
      <input
        type="Text"
        id="BuilderTotalProjects"
        onChange={onChange}
        value={BuilderTotalProjects}
        placeholder="Enter Total Number of Projects of the Builder"
        maxLength="32"
        // required
        className="w-full md:w-1/4 px-4 py-2 text-xl text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mb-6"
      />
      <p className="lg mt-6 font-semibold">Enter the Experience of the Builder</p>
      <input
        type="Text"
        id="BuilderExperience"
        onChange={onChange}
        value={BuilderExperience}
        placeholder="Enter the Experience of the Builder"
        maxLength="32"
        // required
        className="w-full md:w-1/4 px-4 py-2 text-xl text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mb-6"
      />
      <p className="lg mt-6 font-semibold">Enter the Description of the Bulder</p>
      <input
        type="Text"
        id="BuilderDescription"
        onChange={onChange}
        value={BuilderDescription}
        placeholder="Enter the Description of the Builder"
        maxLength="320000"
        // required
        className="w-full md:w-1/4 px-4 py-2 text-xl text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mb-6"
      />
   {/* This is the Builder Logo section */}
   <div className="mb-6">
<p className="text-lg font-semibold">Choose the Builder Logo</p>
<input
  type="file"
  id="BuilderLogo"
  name="BuilderLogo"
  onChange={onChange}
  accept=".jpg,.png,.jpeg, .gif, .bmp, .raw, .svg, .webp"
  required
  className="w-full px-3 py-1.5 text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:bg-white focus:border-slate-600"
/>
{BuilderLogo && ( // Assuming you have a variable called existingLogoUrl containing the URL of the existing logo
  <div className="mt-4">
    <p>Existing Logo:</p>
    <img src={BuilderLogo} alt="Existing Logo" className="max-w-xs mt-2" />
  </div>
)}
</div>

{/* This is the floorPlan section */}

{/*      
    <div className="mb-6">
        <p className="text-lg font-semibold">FloorPlan Images</p>
        <p className="text-gray-600">
          The first image will be the cover
        </p>
        <input
          type="file"
          id="FloorPlanImage"
          name="FloorPlanImage"
          onChange={onChange}
          accept=".jpg,.png,.jpeg, .gif, .bmp, .raw, .svg, .webp"
          multiple
          // required
          className="w-full px-3 py-1.5 text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:bg-white focus:border-slate-600"
        />
      </div> */}

      <button
        type="submit"
        className="mb-6 w-full px-7 py-3 bg-[#005978] text-white font-medium text-sm uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
      >
        Create Residential Project
      </button>
    </form>
  </main>
  );
}