import React, { useEffect } from 'react';
import ItemsContainer from "./ItemsContainer.js";
import SocialIcons from "./SocialIcons.js";
import { Icons } from "./Menus.js";
import logos from "../../assets/svg/theProperty10x.jpeg";
const Footer = () => {
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }, []);
  return (
    <footer className="bg-gray-900 text-white">
      <div className="md:flex md:justify-between md:items-center sm:px-8 px-4 py-3">
        <img
          src={logos}
          alt="Property10X"
          className="md:mb-0 mb-6 lg:leading-normal font-bold md:w-1/6"
        />
        <div>
          <input
            type="text"
            placeholder="Subscribe to Our Newsletter"
            className="text-[#005978] 
           sm:w-72 w-full sm:mr-5 mr-1 lg:mb-0 mb-4 py-2.5 rounded px-2 focus:outline-none"
          />
          <button
            className="bg-[#005978] hover:bg-teal-500 duration-300 px-5 py-2.5 font-[Poppins]
           rounded-md text-white md:w-auto w-full"
          >
            Subscribe
          </button>
        </div>  
      </div>
      <ItemsContainer />
      <div
        className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10
      text-center pt-2 text-white text-sm pb-8"
      >
        <span>© 2023 Property10X All rights reserved.</span>
        <span>Powered by-Prop10X Private Limited</span>
        <span>Terms · Privacy Policy</span>
        <SocialIcons Icons={Icons} />
      </div>
    </footer>
  );
};

export default Footer;
