import React from 'react';
import './City.css';
import { Link } from 'react-router-dom';
import banglore from "../assets/svg/bangloree.png";
import redfort from "../assets/svg/red-fort.png";
import agra from "../assets/svg/agra.png";
import noida from "../assets/svg/noid.png";
import chennai from "../assets/svg/chennai.jpg";
import unity from "../assets/svg/hyderabad-charminar.png";
import mumbai from "../assets/svg/mumbai.png";
import kolkata from "../assets/svg/kolkata.png";
import bhub from "../assets/svg/bhub.png";
import lucknow from "../assets/svg/lucknow.png";
import gurgaon from "../assets/svg/gur.png";
import mathura from "../assets/svg/mathuraa.png"; 
function City() {
  const cities = [
    { name: 'Noida', image: noida, navigate: '/allprojects'},
    { name: 'Delhi', image: redfort },
    { name: 'Agra', image: agra },
    { name: 'Bangalore', image: banglore },
    { name: 'Hyderabad', image: unity },
    { name: 'Mumbai', image: mumbai },
    { name: 'Chennai', image: chennai },
    { name: 'Kolkata', image: kolkata },
    { name: 'Pune', image: bhub },
    { name: 'Lucknow', image: lucknow },
    { name: 'Gurgaon', image: gurgaon },
    { name: 'Mathura', image: mathura },
  ];

  return (
    <>
      <div>
        <h2 className='dream'>
          <span className='text-black'>Find Your Dream Home in Your</span>
          <span className='text-[#005978]'>Dream City</span>
        </h2>
        <p className='blinking-text'>Coming Soon...</p>
      </div>
      <div className="cit">
        <div className="city-list">
          {cities.map((city, index) => (
            <div key={index} className="city-box">
              {city.navigate ? (
                <Link to={city.navigate}>
                  <img src={city.image} alt={city.name} className="city-image" />
                  <div className="city-name">{city.name}</div>
                </Link>
              ) : (
                <>
                  <img src={city.image} alt={city.name} className="city-image" />
                  <div className="city-name">{city.name}</div>
                </>
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default City;
