import React from 'react'
import { useState } from "react";
const Properties = () => {
  const [selectedOption, setSelectedOption] = useState("Residential");
  const [otherOption, setOtherOption] = useState("");

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleOtherOptionChange = (event) => {
    setOtherOption(event.target.value);
  };

  return (
    <div>
      <select value={selectedOption} onChange={handleOptionChange}>
        <option value="Residential">Residential</option>
        <option value="Commercial">Commercial</option>
        <option value="Other">Other</option>
      </select>
      {selectedOption === "Other" && (
        <input
          type="text"
          value={otherOption}
          onChange={handleOtherOptionChange}
        />
      )}
    </div>
  );
};
export default Properties;